import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { CollaboratorsHeaderTitle } from "../components/CollaboratorsHeaderTitle";
import { SearchComponent } from "pages/Team/components/SearchComponent";
import Loading from "components/Loading";
import React, { useEffect, useState } from "react";
import CollaboratorsContainer from "../components/CollaboratorsContainer";
import { Collaborator, CollaboratorPage, CollaboratorsService } from "./CollaboratorsService";
import CollaboratorList from "../components/CollaboratorList";
import { AxiosError } from "axios";
import { PageSelector } from "components/PageSelector";
import { useNavigate, useSearchParams } from "react-router-dom";
import { URL_PAGES } from "utils/constants";
import EasyFeedbackModal from "components/EasyFeedbackModal";
import { CollaboratorsnFeedback } from "../components/CollaboratorsnFeedback";

interface CollaboratorsListProps {
    showEmptyState?: boolean,
    onSubmitSearch: () => void,
    onSearchValueChanged: (value?: string) => void,
    onBackPressed: () => void,
    searchValue?: string
}

function Collaborators(props: CollaboratorsListProps) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [collaboratorPage, setCollaborators] = useState<CollaboratorPage>();
    const [totalPages, setTotalPages] = useState(0)
    const [searchValue, setSearchValue] = useState<string>()
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page") ?? 1));
    const [feedback, setFeedback] = useState<CollaboratorsnFeedback>();
    const [userWithoutTeam, setUserWithoutTeam] = useState(false)

    const onChangePage = (newPage: number) => {
        setCurrentPage(newPage)
        setSearchParams({ page: newPage.toString() })
    };

    const handleChange = () => {
        getCollaboratorList();
    }

    const getCollaboratorList = () => {
        const collaboratorsService = new CollaboratorsService()
        setLoading(true)

        collaboratorsService.getCollaborators(currentPage, 16, searchValue, userWithoutTeam)
            .then((response: CollaboratorPage) => {
                setCollaborators(response)
                setTotalPages(response.lastPage)
            })
            .catch((error: AxiosError | any) => {
                const errorMessage = error?.response?.data?.message
                setFeedback(
                    CollaboratorsnFeedback.createFeedback(
                        "Erro ao buscar colaboradores",
                        errorMessage ?? "Tivemos um problema ao buscar a lista de colaboradores. Por favor, tente novamente."
                    )
                )
            })
            .finally(() => setLoading(false))
    };

    useEffect(() => {
        getCollaboratorList()
    }, [currentPage]);

    useEffect(() => {
        if (searchValue === "") {
            onChangePage(1);
            if (currentPage === 1) {
                getCollaboratorList();
            }
            return
        }
    }, [searchValue]);

    useEffect(() => {
        handleChange();
    }, [userWithoutTeam]);
    
    return (<Container>

        <CollaboratorsHeaderTitle
            title="Gerenciamento de colaboradores"
            description="Selecione um colaborador." />

        <Row className="g-4">
            <Col sm={5} lg={6}>
                <div className="row g-3">
                    <Col lg={2}>
                        <Button variant="outline-primary" onClick={() => navigate(-1)}>
                            Voltar
                        </Button>
                    </Col>
                </div>
            </Col>

            <SearchComponent
                placeholder="Pesquisar colaborador"
                className="col-md-6"
                value={searchValue}
                onSubmitSearch={() => getCollaboratorList()}
                onSearchValueChanged={setSearchValue}
                disabled={userWithoutTeam}
            />

            <div key={`inline-radio`} className="mb-3">
                <Form.Group className="col-12 mt-3" >
                    <Form.Check
                        type="checkbox"
                        label="Colaboradores não atribuídos a um time."
                        checked={userWithoutTeam}
                        onChange={() => {
                            setUserWithoutTeam(!userWithoutTeam);
                        }} />
                </Form.Group>
            </div>
        </Row>

        <CollaboratorsContainer className="mt-3">
            <Loading isLoading={isLoading} />
            <CollaboratorList collaborators={collaboratorPage?.collaborators}
                onItemClicked={(collaborator: Collaborator) => navigate(URL_PAGES.COLLABORATORSDETAIL, { state: { collaborator: collaborator } })} />

            <PageSelector
                onSelectPage={onChangePage}
                pageSelected={currentPage}
                numberOfPages={totalPages} />

        </CollaboratorsContainer>
        <EasyFeedbackModal
            title={feedback?.title}
            description={feedback?.message}
            show={feedback?.show ?? false}
            listener={() => setFeedback(undefined)} />

    </Container>

    );
};
export default Collaborators
