import { SERVICES } from "utils/constants";
import { Team, TeamPage } from "../TeamService";
import api from "api";

interface TeamResponse {
    id: number;
    name: string;
    description?: string;
    'url-image'?: string;
    os?: string
}

export default class TeamListService {
    async getTeams(
        currentPage: number,
        numberOfLines: number,
        searchValue?: string,
    ): Promise<TeamPage> {
        const getParams = () => {
            let params = {
                page: (currentPage - 1).toString(),
                'qtd-lines': numberOfLines.toString(),
                'name': searchValue ?? ""
            }

            const urlSearchParams = new URLSearchParams(params);
            return urlSearchParams.toString()
        };

        const { data } = await api.get(`${SERVICES.TEAM_ADMIN}?${getParams()}`)
        return {
            lastPage: data['last-page'],
            teams: data.teams.map((item: TeamResponse) => new Team(
                item.id,
                item.name,
                item.description,
                item['url-image'],
                item.os
            ))
        }
    }

}