import React from "react";
import { Col, Button, Row, Image } from "react-bootstrap";
import styled from "styled-components";
import PersonImage from "images/person_image.svg";
import { Collaborator } from "../List/CollaboratorsService";

export const ContactCard = styled(Row)`
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin: 5px;
    
`;

export const ContactImage = styled(Image)`
    max-width: fit-content;
    max-height: 120px;
`;

export const ContactImageLarge = styled(Image)`
    max-width: fit-content;
    max-height: 250px;
`;

export const RoleContainer = styled.p`
    background: gray;
    border-radius: 5px;
    color: white;
`;

interface CollaboratorItemProps {
    key: number,
    collaborator: Collaborator,
    onItemClicked: (item: Collaborator) => void
}

export default function CollaboratorItem(props: CollaboratorItemProps) {

    return <Col key={props.key} sm={12} md={6} lg={3}>
        <ContactCard>
            <ContactImage
                roundedCircle
                src={props.collaborator.imageUrl ?? PersonImage}
                alt="imagem do contato" />

            <div className="text-center">
                <h5>{props.collaborator.name}</h5>
                <h6>{props.collaborator.username} </h6>
                {props.collaborator.occupation && <RoleContainer>{props.collaborator.occupation}</RoleContainer>}
            </div>

            <Row className="g-2">
                <Button variant="outline-primary" onClick={() => props.onItemClicked(props.collaborator)}> Visualizar </Button>
            </Row>
        </ContactCard>


    </Col>

}
