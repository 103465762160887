import api from "api";
import { SERVICES } from "utils/constants";

export interface TeamPage {
    lastPage: number,
    teams: Team[]
}

export class Collaborator {
    id: number;
    name: string;
    username: string;
    role?: string;
    imageUrl?: string;

    constructor(
        id: number,
        name: string,
        login: string,
        role?: string,
        imageUrl?: string
    ) {
        this.id = id;
        this.name = name;
        this.username = login;
        this.role = role;
        this.imageUrl = imageUrl;
    }
}

export class Team {
    id: number;
    name: string;
    description?: string;
    imageUrl?: string;
    os?: string;

    constructor(
        id: number,
        name: string,
        description?: string,
        imageUrl?: string,
        os?: string
    ) {
        this.id = id
        this.name = name
        this.description = description
        this.imageUrl = imageUrl
        this.os = os
    }
}

export class TeamService {
    async saveImage(teamName: string, imageSelected?: File, currentImageUrl?: string): Promise<string | undefined> {
        let imageUrl: string | undefined = currentImageUrl
        if (imageSelected) {
            let formData = new FormData();
            formData.append("file", imageSelected)
            let { data } = await api.post(SERVICES.TEAM_IMAGE_UPLOAD + `/${teamName}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            imageUrl = data
        }
        return imageUrl
    }
}