import { Modal } from "react-bootstrap";
import { Collaborator, Team } from "pages/Team/TeamService";
import AddTeamSelectedForm from "./AddTeamSelectedForm";

interface AddTeamModalProps {
    team: Team | undefined,
    show: boolean,
    onCloseModal: () => void,
    onSubmitValidForm: (team: Team, date: string) => void
}

export default function AddTeamModal(props: AddTeamModalProps) {
    return props?.team && <div>
        <Modal size="xl" show={props.show} onHide={props.onCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Preencha os dados para adicionar um time ao colaborador</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AddTeamSelectedForm
                    dateLabel="Data de entrada:"
                    buttonVariant="primary"
                    buttonLabel="Adicionar"
                    team={props.team}
                    onCollaboratorSelected={props.onSubmitValidForm}
                    onCancel={props.onCloseModal}
                />
            </Modal.Body>
        </Modal>
    </div>
}