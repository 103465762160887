import { Collaborator } from "pages/Team/TeamService";
import { useState } from "react";
import { Button, Container, Form, Row } from "react-bootstrap";
import moment from "moment";

export default function CollaboratorSelectedForm(
    props: {
        collaborator?: Collaborator,
        buttonVariant: string,
        buttonLabel: string,
        dateLabel: string,
        onCollaboratorSelected: (collaborator: Collaborator, dateInit: string) => void,
        onCancel: () => void
    }
) {
    const [formValid, setFormValid] = useState(false);
    const [dateSelected, setDateSelected] = useState<string>();

    const onSubmitForm = (event: React.BaseSyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            props.collaborator &&
                dateSelected &&
                props.onCollaboratorSelected(props.collaborator, moment.utc(new Date(dateSelected)).format('DD/MM/YYYY'))
        }
        setFormValid(true);
    };

    const onDateSelected = (date: string | undefined) => {
        date && setDateSelected(date)
    };

    return <Container>
        <Form
            id="collaborator-form"
            className="row g-1"
            noValidate
            validated={formValid}
            onSubmit={onSubmitForm}>

            <Row className=" d-flex justify-content-center g-2">
                <Form.Group className="col-12">
                    <Form.Label>Colaborador selecionado:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Nome do colaborador"
                        name="name"
                        value={props.collaborator?.name}
                        readOnly />
                </Form.Group>
                <Form.Group className="col-12" >
                    <Form.Label>{props.dateLabel}</Form.Label>
                    <Form.Control
                        name="date"
                        value={dateSelected}
                        required
                        onChange={(e) => onDateSelected(e.target.value)}
                        type="date" />
                    <Form.Control.Feedback type="invalid">
                        É necessário inserir uma data
                    </Form.Control.Feedback>
                </Form.Group>
                <Row className="g-2">
                    <Button
                        variant={props.buttonVariant}
                        type="submit">
                        {props.buttonLabel}
                    </Button>
                    <Button
                        onClick={props.onCancel}
                        variant="outline-secondary">
                        Cancelar
                    </Button>
                </Row>
            </Row>
        </Form>
    </Container>
}

