import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";

const ItemContainer = styled(Row)`
    text-align: center;
    color: #8f8f8f;
    border: solid 1px #e6e6e6;
    border-radius: 5px;
    padding: 8px;
    box-shadow: 5px 5px 8px 2px rgba(233, 233, 233, 0.8);
`;

const TeamName = styled.h5`
    display: inline-block;
    margin-right: 10px;
`;

interface TeamData {
    id: number,
    name: string,
    image?: string,
}

interface TeamProps {
    team: TeamData,
    onClick: (team: TeamData) => void,
    buttonLabel: string
}

export default function TeamListItem(props: TeamProps) {
    let team = props.team;
    return (
        <Col sm={4}>
            <ItemContainer className="g-1">
                <TeamName>{team.name}</TeamName>
                <Button variant="outline-primary" onClick={() => props.onClick(team)}>{props.buttonLabel}</Button>
            </ItemContainer>
        </Col>
    );
}