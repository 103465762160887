import React, { useState } from "react";
import { Button, Form, Alert, Spinner } from "react-bootstrap";
import api from "../../api";
import { HEADER_CONTENT_TYPE_JSON, SERVICES } from "../../utils/constants";
import { session } from "../../utils";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowError(false);

    if (!email | !senha) {
      setError("Preencha todos os campos");
      return;
    }

    try {
      setLoading(true);
      const login = email;
      const password = senha;
      const response = await api.post(
        SERVICES.LOGIN,
        JSON.stringify({ login, password }),
        HEADER_CONTENT_TYPE_JSON
      );
      session.setAuthToken(response?.data?.authToken);
      session.setPermissions(
        response?.data?.permissoes.map((permissao) => permissao.code)
      );
    } catch (error) {
      if (error?.code === '"ERR_NETWORK"') {
        setError("Falha na comunicação com o servidor.");
      } else {
        setError(error.response?.data?.message);
      }
      session.clearAuthToken();
      session.clearPermissions();
      setShowError(true);
      console.log()
      return;
    } finally {
      setLoading(false);
    }

    window.location.assign("/");
  };

  let button;
  if (+loading) {
    button = (
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Carregando...
      </Button>
    );
  } else {
    button = (
      <Button variant="primary" type="submit">
        Entrar
      </Button>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label> Usuário </Form.Label>
        <Form.Control
          type="text"
          placeholder="Digite seu Login"
          onChange={(e) => [
            setEmail(e.target.value),
            setError(""),
            setShowError(false),
          ]}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label> Senha </Form.Label>
        <Form.Control
          type="password"
          placeholder="Digite sua Senha"
          onChange={(e) => [
            setSenha(e.target.value),
            setError(""),
            setShowError(false),
          ]}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicError">
        <Alert key="danger" variant="danger" show={showError}>
          {error}
        </Alert>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        {button}
      </Form.Group>
    </Form>
  );
};

export default Signin;
