import React, { ReactNode } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

const ContainerWrapper = styled(Container)`
padding: 30px;
border-radius: 20px;
box-shadow: 1px 1px 3px #777777;
border: 1px solid lightgrey;
`;

interface Props {
    children?: ReactNode,
    className?: string
}

export default function TeamContainer(props: Props) {
    return <ContainerWrapper className={props.className}>
        {props.children}
    </ContainerWrapper>
}