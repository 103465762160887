import React, { useEffect, useState } from "react";
import { Col, Row, Container, Alert } from "react-bootstrap";
import GridItem from "./GridItem.js"
import { AVAILABLE_FEATURES } from "./features.js"
import { useNavigate } from 'react-router-dom';

const Home = ({ hasPermission = false, showLogin = false }) => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [availableFeatures, setAvailableFeatures] = useState([]);
  useEffect(() => {
    if (!hasPermission && !showLogin) {
      setShowError(true)
    }
    setAvailableFeatures(AVAILABLE_FEATURES)
  }, [hasPermission, showLogin])

  if (showError) {
    return (
      <Alert key='danger' variant='danger' show={showError} >
        <Alert.Heading>Sem permissão.</Alert.Heading>
      </Alert>
    );
  }

  if (showLogin) {
    return (
      <Alert show={showLogin} variant="danger">
        <Alert.Link className="justify-content-center" href="/login">Efetuar login</Alert.Link>
      </Alert>
    );
  }

  return (
    <Container>
      <Alert show={hasPermission} variant="success">
        <Alert.Heading>Bem vindo ao TQI Easy Admin</Alert.Heading>
        <p className="m-0">Selecione uma opção no meu abaixo para começar</p>
      </Alert>
      <Row className="g-4 mb-3">
        {availableFeatures.map(feature => (
          <Col xs={6} md={4} lg={3} key={feature.url}>
            <GridItem
              title={feature.title}
              image={feature.image}
              onItemClicked={() => navigate(feature.url)} />
          </Col>
        ))}
      </Row>
    </Container >
  );
};

export default Home;
