import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { TeamItem, TeamList } from "./components/TeamList";
import { PageSelector } from "components/PageSelector";
import { URL_PAGES } from "utils/constants";
import Loading from "components/Loading";
import { TeamHeaderTitle } from "../components/TeamHeaderTitle";
import EasyFeedbackModal from "components/EasyFeedbackModal";
import { AxiosError } from "axios";
import { Team, TeamPage } from "../TeamService";
import TeamListService from "./TeamListService";

class TeamListFeedback {
    title: string;
    description: string;
    show: boolean;

    constructor(title: string, description: string) {
        this.title = title
        this.description = description
        this.show = true
    }
}

export default function TeamsList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [teamsList, setTeamsList] = useState<TeamItem[]>()
    const [loading, showLoading] = useState(true)
    const [totalPages, setTotalPages] = useState(0)
    const [emptyState, showEmptyState] = useState(false)
    const [errorToLoadTeams, setErrorToLoadTeams] = useState<TeamListFeedback>()
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page") ?? 1));
    const [searchValue, setSearchValue] = useState<string>()
    const navigate = useNavigate();

    const onChangePage = (newPage: number) => {
        setCurrentPage(newPage)
        setSearchParams({ page: newPage.toString() })
    };

    const getTeams = () => {
        const service = new TeamListService();
        showLoading(true)
        setErrorToLoadTeams(undefined)

        service.getTeams(currentPage, 15, searchValue)
            .then((page: TeamPage) => {
                setTotalPages(page.lastPage)
                const teams = page.teams.map((team: Team) => {
                    return {
                        id: team.id,
                        name: team.name,
                        description: team.description,
                        image: team.imageUrl,
                        os: team.os
                    }
                })
                setTeamsList(teams)
                let teamItems = teamsList ?? []
                if (teamItems.length > 0) {
                    showEmptyState(false)
                } else {
                    showEmptyState(true)
                }
            })
            .catch((error: AxiosError | any) => {
                const errorMessage = error?.response.data?.message
                setErrorToLoadTeams(new TeamListFeedback(
                    "Houve um erro",
                    errorMessage ?? "Tivemos um erro ao buscar as informações, por favor tente novamente."
                ))
            })
            .finally(() => showLoading(false))
    };

    useEffect(() => {
        getTeams()
    }, [currentPage]);

    useEffect(() => {
        if (searchValue === "") {
            onChangePage(1);
            if (currentPage === 1) {
                getTeams();
            }
            return
        }
    }, [searchValue]);

    return (
        <Container>
            <Loading isLoading={loading} />
            <TeamHeaderTitle
                title="Gerenciamento de times"
                description="Selecione um time, edite as informações, adicione ou remova colaboradores, etc." />
            <TeamList
                searchValue={searchValue}
                onSubmitSearch={() => getTeams()}
                onSearchValueChanged={setSearchValue}
                teams={teamsList}
                onItemClicked={(team: TeamItem) => navigate(URL_PAGES.TEAM_EDITION, { state: { team: team } })}
                showEmptyState={emptyState}
                onCreateTeam={() => navigate(URL_PAGES.TEAM_CREATION)}
                onBackPressed={() => navigate(-1)} />
            <PageSelector
                onSelectPage={onChangePage}
                pageSelected={currentPage}
                numberOfPages={totalPages} />
            <EasyFeedbackModal
                title={errorToLoadTeams?.title}
                description={errorToLoadTeams?.description}
                show={errorToLoadTeams?.show ?? false}
                listener={() => setErrorToLoadTeams(undefined)} />
        </Container>
    );
}