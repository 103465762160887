import Logo from "../Logo";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { PERMISSIONS, URL_PAGES } from "../../utils/constants";
import styled from "styled-components";
import { session } from "../../utils";
import { useState } from "react";

const NavLink = styled(Nav.Link)`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  color: ${(props) =>
    props.href !== window.location.pathname ||
    props.ignoreselection
      ? "white"
      : "red"};
  text-align: center;

  &:hover {
    color: red;
  }
`;

const NavStyles = styled.div`
  background-color: var(--primary-color);

  .navbar {
    width: 100%;
  }
`;

function Header() {
  const userHasPermission = (permission) => {
    return session.permissions().includes(permission);
  };

  const isLogged = () => {
    return session.authToken() !== "";
  };

  const logout = () => {
    session.clearAuthToken();
    session.clearPermissions();
  };

  return (
    <NavStyles>
      <Navbar expand="md">
        <Container>
          <Container className="col-2 d-flex align-items-center justify-content-center">
            <Navbar.Brand href="#home">
              <Logo />
            </Navbar.Brand>
          </Container>

          <Container className="justify-content-start">
            <Nav>
              <NavLink href="/" visible={+true}>
                Home
              </NavLink>
              <NavLink
                href={URL_PAGES.TEAMS}
                visible={+userHasPermission(PERMISSIONS.teams_adm)}
              >
                Times
              </NavLink>
              <NavLink
                href={URL_PAGES.NOTIFICATIONS}
                visible={+userHasPermission(PERMISSIONS.notifications)}
              >
                Notificações
              </NavLink>
              <NavLink
                href={URL_PAGES.ONCALL}
                visible={+userHasPermission(PERMISSIONS.hour_oncall_admin)}
              >
                Sobreaviso
              </NavLink>
              <NavLink
                href={URL_PAGES.HOUR_EXCEPTION}
                visible={
                  +userHasPermission(PERMISSIONS.hour_exception_throw_admin)
                }
              >
                Exceções
              </NavLink>
              <NavLink
                href={URL_PAGES.EVENTS}
                visible={+userHasPermission(PERMISSIONS.events_adm)}
              >
                Eventos
              </NavLink>
              <NavLink
                href={URL_PAGES.EVIDENCE}
                visible={+userHasPermission(PERMISSIONS.evidence_approver)}
              >
                Gestão de evidências
              </NavLink>
            </Nav>
          </Container>

          <Container>
            <Nav className="justify-content-end">
              <NavLink
                visible={+isLogged()}
                href="/"
                onClick={logout}
                ignoreselection={+true}
              >
                Sair
              </NavLink>
            </Nav>
          </Container>
        </Container>
      </Navbar>
    </NavStyles>
  );
}

export default Header;
