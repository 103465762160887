import { SERVICES } from "utils/constants";
import { Team, TeamService } from "../TeamService"
import api from "api";

class CreateTeamRequest {
    'nome': string;
    'descricao'?: string;
    'url-imagem'?: string;
    os?: string

    constructor(
        name: string,
        description?: string,
        imageUrl?: string,
        os?: string
    ) {
        this['nome'] = name
        this['descricao'] = description
        this["url-imagem"] = imageUrl
        this.os = os
    }
}


export default class CreateTeamService {
    async createTeam(
        name: string,
        description?: string,
        teamImageUrl?: string,
        os?: string,
        imageSelected?: File,
    ): Promise<Team> {
        const teamService = new TeamService();
        const imageUrl = await teamService.saveImage(name, imageSelected, teamImageUrl)

        const body = new CreateTeamRequest(
            name,
            description,
            imageUrl,
            os
        )
        const { data } = await api.post(SERVICES.TEAM, body)
        return new Team(
            data.id,
            data.nome,
            data.descricao,
            data['url-imagem'],
            data.os
        )
    }
}