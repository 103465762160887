import { useEffect, useState } from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";
import { Collaborator } from "pages/Team/TeamService";
import UserService from "../EditTeamService";
import { AxiosError } from "axios";
import { SearchComponent } from "pages/Team/components/SearchComponent";
import CollaboratorItem from "./CollaboratorItem";
import CollaboratorSelectedForm from "./CollaboratorSelectedForm";

interface SelectCollaboratorModalProps {
    collaboratorSelected: (collaborator: Collaborator, date: string) => void,
    show: boolean,
    onCloseModal: () => void
}

function ErrorFeedbackComponent(props: { tryAgain: () => void, message: string }) {
    return <div>
        <h3>Ops, houve um erro!</h3>
        <p>{props.message}</p>
        <Button onClick={props.tryAgain}>Tentar novamente</Button>
    </div>
}

function CollaboratorListComponent(
    props: {
        collaborators?: Collaborator[],
        onCollaboratorSelected: (collaborator: Collaborator) => void,
        searchValue: string,
        onSearchValueChanged: (value?: string) => void
    }
) {
    return <Container>
        <Row>
            <SearchComponent
                className="col-11"
                placeholder="Digite o nome do colaborador"
                value={props.searchValue}
                onSubmitSearch={() => { }}
                onSearchValueChanged={props.onSearchValueChanged}
            />
        </Row>
        <Row>
            {props.collaborators?.map((collaborator: Collaborator, index) => {
                return <CollaboratorItem
                    onClickToRemoveCollaborator={() => props.onCollaboratorSelected(collaborator)}
                    key={index}
                    buttonLabel="Selecionar"
                    item={collaborator} />
            })}
        </Row>
    </Container>
}

function InternalLoading(props: { show: boolean }) {
    return props.show && <div className="m-4 row d-flex justify-content-center text-center">
        <div className="col-2">
            <div className='loader' />
        </div>
        <p>Carregando...</p>
    </div>;
}

export function SelectCollaboratorModal(props: SelectCollaboratorModalProps) {

    const [isLoading, showLoading] = useState(false);
    const [errorFeedback, setErrorFeedback] = useState<string>();
    const [searchValue, setSearchValue] = useState<string>("");
    const [collaborators, setCollaborators] = useState<Collaborator[]>();
    const [filteredCollaborators, setFilteredCollaborators] = useState<Collaborator[]>();
    const [collaboratorSelected, setCollaboratorSelected] = useState<Collaborator>();

    const getCollaboratorList = () => {
        showLoading(true);
        const service = new UserService()
        setErrorFeedback(undefined)

        service.getCollaborators()
            .then((response: Collaborator[]) => setCollaborators(response))
            .catch((error: AxiosError | any) => {
                const errorMessage = error?.response.data?.message
                setErrorFeedback(errorMessage ?? "Por favor tente novamente...")
            })
            .finally(() => showLoading(false))
    }

    const onFilterUser = (name?: string) => {
        setSearchValue(name ?? "");
        if (name === "" || name === undefined) {
            setFilteredCollaborators(collaborators?.slice(0, 20));
            return
        }

        let newList = collaborators?.filter((collaborator: Collaborator) => {
            const nameLowerCase = name.toLowerCase()
            const collaboratorName = collaborator.name.toLowerCase()
            const collaboratorNameNormalized = collaboratorName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            return collaboratorNameNormalized.includes(nameLowerCase) || collaboratorName.includes(nameLowerCase)
        })
        setFilteredCollaborators(newList);
    }

    useEffect(() => {
        if (props.show) {
            setSearchValue("");
            setCollaborators(undefined);
            setCollaboratorSelected(undefined);
            getCollaboratorList();
        }
    }, [props.show]);

    useEffect(() => {
        setFilteredCollaborators(collaborators?.slice(0, 20));
    }, [collaborators])

    return (
        <div>
            <Modal size="xl" show={props.show} onHide={props.onCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Selecione um colaborador abaixo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {collaboratorSelected ?
                        <CollaboratorSelectedForm
                            dateLabel="Data de início:"
                            buttonVariant="primary"
                            buttonLabel="Adicionar"
                            collaborator={collaboratorSelected}
                            onCollaboratorSelected={props.collaboratorSelected}
                            onCancel={props.onCloseModal}
                        /> :
                        errorFeedback ?
                            <ErrorFeedbackComponent
                                tryAgain={() => getCollaboratorList()}
                                message={errorFeedback} /> :
                            <CollaboratorListComponent
                                collaborators={filteredCollaborators}
                                onCollaboratorSelected={setCollaboratorSelected}
                                onSearchValueChanged={onFilterUser}
                                searchValue={searchValue} />
                    }
                    <InternalLoading show={isLoading} />
                </Modal.Body>
            </Modal>
        </div>
    );

}