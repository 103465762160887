import React from "react";
import { Button, Container, Row } from "react-bootstrap";

export default function TeamCreationFormButtons() {
    return (
        <Container>
            <Row className="d-flex justify-content-center mt-3">
                <Button
                    className="col-xl-4 col-md-8"
                    variant="primary"
                    type="submit">
                    Criar time
                </Button>
            </Row>
        </Container>
    );
}