import React, { useState } from "react";
import { TeamHeaderTitle } from "../components/TeamHeaderTitle";
import { HandleTeamForm, TeamFormParams } from "../components/HandleTeamForm";
import Loading from "components/Loading";
import { useNavigate } from "react-router-dom";
import EasyFeedbackModal from "components/EasyFeedbackModal";
import TeamContainer from "../components/TeamContainer";
import { Team } from "../TeamService";
import { AxiosError } from "axios";
import TeamCreationFormButtons from "./components/TeamCreationFormButtons";
import CreateTeamService from "./CreateTeamService";
import BackButton from "../components/BackButton";

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>

class TeamCreationFeedback {
    title: string;
    message: string;
    show: boolean;
    clickListener: () => void;

    constructor(title: string, message: string, listener: () => void) {
        this.title = title
        this.message = message
        this.show = true
        this.clickListener = listener
    }

    static createFeedback(
        title: string,
        message: string,
        listener: () => void
    ) {
        return new TeamCreationFeedback(title, message, listener)
    }
}

const initialFormParams = {
    name: "",
    description: "",
    image: undefined,
    os: ""
}

export default function CreateTeam() {
    const navigate = useNavigate()

    const [formParams, setFormParams] = useState<TeamFormParams>(initialFormParams);
    const [fileSelected, setFileSelected] = useState<File>();
    const [isFormValid, setFormValid] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<TeamCreationFeedback>();

    const handleChange = (event: ChangeEvent) => {
        let { name, value } = event.target
        console.log(name)
        setFormParams({ ...formParams, [name]: value })
    }

    const handleImageChanged = (event: any) => {
        let files = event?.target?.files
        let file: File | undefined
        if (files && files[0]) {
            file = files[0]
        }
        let imageUrl = file && URL.createObjectURL(file);
        setFileSelected(file)
        setFormParams({ ...formParams, image: imageUrl })
    }

    const onSubmitForm = (event: React.BaseSyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            createTeam()
        }
        setFormValid(true);
    };

    const createTeam = () => {
        let teamService = new CreateTeamService();
        setLoading(true)
        teamService.createTeam(
            formParams.name,
            formParams.description,
            formParams.image,
            formParams.os,
            fileSelected
        ).then((team: Team) => {
            console.log(team);
            setFeedback(
                TeamCreationFeedback.createFeedback(
                    "Time criado com sucesso",
                    "O time foi criado com sucesso, clique abaixo para voltar",
                    () => { navigate(-1) }
                )
            )
        }).catch((error: AxiosError | any) => {
            const errorMessage = error?.response.data?.message
            setFeedback(
                TeamCreationFeedback.createFeedback(
                    "Houve um erro ao criar o time",
                    errorMessage ?? "Tivemos um problema ao salvar as alterações. Por favor, tente novamente.",
                    () => { setFeedback(undefined) }
                )
            )
        }).finally(() => setLoading(false))
    };

    return (
        <TeamContainer>
            <Loading isLoading={isLoading} />
            <TeamHeaderTitle
                title={"Criação de times"}
                description="Insira as informaçoes do time no formulário abaixo" />
            <BackButton onClick={() => navigate(-1)} />
            <HandleTeamForm
                onChangeField={handleChange}
                onSubmitForm={onSubmitForm}
                onSelectImage={handleImageChanged}
                formValid={isFormValid}
                onlyReadName={false}
                teamParams={formParams}>
                <TeamCreationFormButtons />
            </HandleTeamForm>
            <EasyFeedbackModal
                show={feedback?.show ?? false}
                title={feedback?.title}
                description={feedback?.message}
                listener={() => feedback?.clickListener()} />
        </TeamContainer>
    );
}