/* istanbul ignore file */
export const URL_PAGES = {
  HOME: "/",
  LOGIN: "/login",
  EVENTS: "/eventos",
  EVENT_DETAILS: "/eventos/:id",
  PARTNER: "/parceiros",
  ALURA: "/alura",
  NOTIFICATIONS: "/notificacoes",
  ONCALL: "/sobreaviso",
  HOUR_EXCEPTION: "/horas-excecoes",
  NOTIFICATIONS_REGISTER: "/notificacoes/cadastro",
  NOTIFICATIONS_LIST: "/notificacoes/lista",
  HELPDESK: "/suporte",
  FAQ: "/ajuda",
  SOLICITATIONS: "/solicitacoes",
  TEAMS: "/times",
  TEAM_CREATION: "/team/create",
  TEAM_EDITION: "/team/edit",
  EVIDENCE: "/evidences",
  ESCALATION_HOME: "/escalation",
  ESCALATION_LIST: `/escalation/team`,
  ESCALATION_CREATION: "/escalation/create",
  COLLABORATORSDETAIL: "/colaboradores/detalhes",
  COLLABORATORS: "/colaboradores",
  INVOICE: "/collaborator/invoices",
};

export const SERVICES = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  USER: "/user",
  EVENTS: "/event",
  EVENTS_LIST: "/event/admin",
  DEPARTAMENTS: "/user/resolution-centers",
  USERS_ADMIN: "/user/admin/actives",
  NOTIFICATION: "/notification",
  MANAGER_BU: "/academy/all-manager-bus",
  SOLICITATIONS: "/academy/find-all-by-bu",
  EDIT_SOLICITATION: "/academy/solicitation",
  ONCALL: "/hour-oncall",
  ONCALL_ALLOW_LAUNCH: "/hour-oncall/allow-launch",
  ONCALL_APPROVE: "/approve",
  ONCALL_UNAPPROVE: "/not-approve",
  ONCALL_LIST_REQUESTED: "/hour-oncall/requested",
  ONCALL_ADMIN_LIST_REQUESTED: "/hour-oncall/admin/requested",
  ONCALL_LIST_APPROVED: "/hour-oncall/approved",
  TEAM_ADMIN: `/team/admin`,
  TEAM_ADMIN_ADD_USER: (teamId, username) => `/team/${teamId}/collaborator`,
  TEAM_ADMIN_DELETE_USER: (teamId, userId, exitDate) => `/team/${teamId}/collaborator/${userId}/${exitDate}`,
  TEAM: `/team`,
  TEAM_IMAGE_UPLOAD: `/team/team-image`,
  TEAM_WITH_COLLABORATORS: "/team/admin/with-collaborators",
  TEAM_COLLABORATOR: "/collaborator",
  TEAM_UPLOAD_IMAGE: "/team/team-image",
  SYNC: "/sync",
  SYNC_ONCALL_LAUNCHERS: "/sync/on-call-gp-synchronize",
  SYNC_ONCALL_PERMISSIONS: "/sync/on-call-permissions",
  SYNC_HOUR_EXCEPTION: "/sync/hour-exception",
  FILE_STORAGE: "/file/storage",
  V2_HOUR_ADMIN: "/v2/hour/admin/",
  MANAGEMENT_EVIDENCE_LIST: (subMenu) => `/evidence/management/${subMenu}`,
  MANAGEMENT_EVIDENCE_APPROVE: (id) => `/evidence/approve/${id}`,
  MANAGEMENT_EVIDENCE_REPROVE: (id) => `/evidence/reprove/${id}`,
  ESCALATION_LIST: (teamId) => `/escalation/team/${teamId}`,
  ESCALATION: "/escalation"
};

export const ERROR_FIELDS = {
  EMPTY_REQUIRED: "Campo obrigatório",
};

export const ERROR_MESSAGES = {
  DEFAULT: {
    code: "0",
    message: "Não foi possível comunicar com o serviço solicitado.",
  },
};

export const PERMISSIONS = {
  notifications: "create_notification",
  admin_patrimony: "patrimony_adm",
  candidate_adm: "candidate_adm",
  collaborator_edit_profile: "collaborator_edit_profile",
  hour_oncall_approving: "hour_oncall_approving",
  hour_oncall_admin: "hour_oncall_admin",
  reservation: "reservation",
  hour_exception_throw_admin: "hour_exception_throw_admin",
  hour_exception_delete: "hour_exception_delete",
  communication_slack: "communication_slack",
  events_adm: "events_adm",
  teams_adm: "teams_adm",
  oncall_approver: "hour_oncall_approving",
  oncall_delete: "oncall_delete",
  evidence_approver: "evidence_approvers",
  escalation_management: "escalation_adm",
  collaborator_invoice: "collaborator_invoice",
};

export const DEFAULT_VARIANT = "outlined";

export const EXPIRED_TOKEN_CODE = "1008";

export const DEFAULT_DEVICE = "WEB";

export const DEFAULT_APP_VERSION = "1.0.0";

export const DEFAULT_DEVICE_TYPE = "2";

export const HEADER_CONTENT_TYPE_JSON = {
  headers: {
    "Content-Type": "application/json",
  },
};
