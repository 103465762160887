import api from "api";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";

export enum InvoiceStatus {
    GENERATING_PAYMENT = "GENERATING_PAYMENT",
    WAITING_UPLOAD = "WAITING_UPLOAD",
    UPLOADED = "UPLOADED",
    APPROVED = "APPROVED",
    INVALID = "INVALID"
}

export enum InvoiceType {
    MONTHLY = "MONTHLY",
    COMPLEMENT = "COMPLEMENT",
}

export class Invoice {
    type: string
    idPayment: number
    seqRH: number
    competence: string
    status: string
    observation?: string

    constructor(type: string, idPayment: number, seqRH: number, competence: string, status: string, observation?: string) {
        this.type = type;
        this.idPayment = idPayment;
        this.seqRH = seqRH;
        this.competence = competence;
        this.status = status;
        this.observation = observation;
    }
}

interface Result {
    type: string;
}

interface SuccessResult extends Result {
    type: 'success';
    invoices: Invoice[]
}

interface ErrorResult extends Result {
    type: 'error';
    message: string;
}

interface LoadingResult extends Result {
    type: 'loading';
}

export type ServiceResult = SuccessResult | ErrorResult | LoadingResult;

export function useInvoiceService(): any[] {
    const [state, setState] = useState<ServiceResult>();

    const onLoadInvoiceError = (error: AxiosError | any) => {
        setState({ type: 'error', message: error.response?.data?.message ?? "Houve um erro ao carregar a lista para upload, tente novamente!" })
    };

    const getInvoices = async () => {
        setState({ type: 'loading' })
        await api.get("/user/invoice")
            .then((response) => response.data)
            .then((data) => setState({ type: 'success', invoices: data }))
            .catch(onLoadInvoiceError)
    };

    useEffect(() => {
        getInvoices()
    }, [])

    return [state, () => getInvoices()];
}