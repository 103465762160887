import React from "react";
import { Col, Button, Row, Image } from "react-bootstrap";
import styled from "styled-components";
import PersonImage from "images/person_image.svg";
import { Collaborator } from "pages/Team/TeamService";

const ContactCard = styled(Row)`
    border: 1px solid lightgray;
    border-radius: 5px;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin: 5px;
`;

const ContactImage = styled(Image)`
    max-width: fit-content;
    max-height: 120px;
`;

const RoleContainer = styled.p`
    background: gray;
    border-radius: 5px;
    width: fit-content;
    color: white;
`;

interface Item {
    id: number,
    username: string,
    name: string,
    role?: string,
    imageUrl?: string
};

interface CollaboratorItemProps {
    key: number,
    item: Item,
    buttonLabel: string,
    onClickToRemoveCollaborator: (collaborator: Collaborator) => void
}

export default function CollaboratorItem(props: CollaboratorItemProps) {
    return <Col key={props.key} sm={12} md={6} lg={3}>
        <ContactCard>
            <ContactImage
                roundedCircle
                src={props.item.imageUrl ?? PersonImage}
                alt="imagem do contato" />
            <h5>{props.item.name}</h5>
            <p>{props.item.username}</p>
            {props.item.role && <RoleContainer>{props.item.role}</RoleContainer>}
            <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => props.onClickToRemoveCollaborator(props.item)}>
                {props.buttonLabel}
            </Button>
        </ContactCard>
    </Col>

}