import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import EmptyStateImage from "images/empty_box.svg";
import CollaboratorItem from "./CollaboratorItem";
import EmptyState from "components/EmptyState";

export interface Collaborator {
    id: number,
    username: string,
    name: string,
    role?: string,
    imageUrl?: string
}

interface CollaboratorListProps {
    collaborators?: Collaborator[],
    onItemClicked:(collaborator: Collaborator) => void
}

export default function CollaboratorList(props: CollaboratorListProps) {
    return (
        <Container>
            <Row className="g-2">
                <h3 className="col"> Lista de colaboradores </h3>
            </Row>
            <div>
                {props?.collaborators ?
                    <Row className="gy-1 mt-1">
                        {props?.collaborators?.map((item, index) =>
                            <CollaboratorItem
                                key={index}
                                collaborator={item}
                                onItemClicked={props.onItemClicked} />
                        )}
                    </Row> : <EmptyState
                        className="mt-3"
                        message={"Nenhum colaborador encontrado..."}
                        img={EmptyStateImage}
                    />
                }
            </div>
        </Container>
    );
}