import logo from "../../images/logo.svg";

function Logo() {
  return (
    <div>
      <img src={logo} alt="TQI" />
    </div>
  );
}

export default Logo;