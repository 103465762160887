import React from "react";
import "./style.css";

const Loading = ({isLoading}) => {
    return isLoading && (
        <div style={{ display: isLoading ? 'flex' : 'none' }} className='modal'>
        <div className='modal-content'>
          <div className='loader'></div>
          <div className='modal-text'>Carregando...</div>
        </div>
      </div>
    );
}

export default Loading;