import React from "react";
import { Row, Alert } from "react-bootstrap";

interface HeaderProps {
    title: string,
    description: string
}

export function TeamHeaderTitle(props: HeaderProps) {
    return (
        <Row>
            <Alert variant="info">
                <Alert.Heading>{props.title}</Alert.Heading>
                <p>{props.description}</p>
            </Alert>
        </Row>
    );
};