import { Container, Row } from "react-bootstrap"
import { Invoice } from "../hooks/useInvoiceService"
import InvoiceUploadItem from "./InvoiceUploadItem"
import EmptyState from "components/EmptyState"
import EmptyStateImage from 'images/empty_box.svg';

interface InvoiceListProps {
    invoices: Invoice[],
    onFileSelected: (invoice: Invoice, file: File | null) => void
}

export function InvoiceUploadList(props: InvoiceListProps) {
    return <Container>
        <Row className="g-1">
            {props.invoices.map((item) => {
                return <InvoiceUploadItem onFileSelected={props.onFileSelected} invoice={item} />
            }) ?? <EmptyState
                    className="mt-3"
                    message={"Nenhuma nota fiscal disponível para apontamento..."}
                    img={EmptyStateImage}
                />}
        </Row>
    </Container>
}