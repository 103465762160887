import { useState, useEffect } from 'react'
import { Alert, Col, Form, Row, Table } from 'react-bootstrap'
import { session } from 'utils'
import { HEADER_CONTENT_TYPE_JSON, PERMISSIONS, SERVICES } from 'utils/constants'
import api from '../../api'

const ListApproved = (props) => {
  const [showError, setShowError] = useState(false)
  const [error, setError] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)
  const [success, setSuccess] = useState("")
  const [onCalls, setOnCalls] = useState([])

  const initialized = () => {
    setError("")
    setSuccess("")
    setShowError(false)
    setShowSuccess(false)
  }
  
  useEffect(() => {
    if (session.permissions().includes(PERMISSIONS.oncall_approver)) {
      findApproved()
    }
  // eslint-disable-next-line
  }, [])

  const findApproved = async() => {
    initialized()
    try {
      await api.get( SERVICES.ONCALL_LIST_APPROVED, HEADER_CONTENT_TYPE_JSON )
      .then(response => {
        setOnCalls(response?.data)
      })
    } catch (error) {
      setError(error.response?.data?.message)
      setShowError(true)
      return;
    }
  }  

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="approvedAlert">
        <Col sm="12">
          <Alert key='danger' variant='danger' show={showError}>
            {error}
          </Alert>
          <Alert show={showSuccess} variant="success">                
            <Alert.Heading>{success}</Alert.Heading>
          </Alert>
        </Col>
      </Form.Group>
      {props.showListApproved && onCalls.length > 0 ? 
        (
          <Table striped>        
            <thead>
              <tr>
                <th>Colaborador</th>
                <th>Data</th>
                <th>Status</th>
                <th>Descrição</th>
              </tr>
            </thead>
            <tbody>
              {onCalls.map(value => (
                <tr key={value.id}>
                  <td>{value['worked-by']}</td>
                  <td>{value['oncall-date']}</td>
                  <td>{value.status}</td>
                  <td>{value['desc-type-oncall']}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : 
        (<></>)
      }
      {props.showListApproved && onCalls.length < 1 ? 
        (
          <Form.Group>
            <Alert variant="success">                
              <Alert.Heading>Nenhum lançamento aprovado</Alert.Heading>
            </Alert>
          </Form.Group>
        ) : 
        (<></>)
      }
    </>
  )
}

export default ListApproved