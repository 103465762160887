import { useState, useEffect } from 'react'
import { Alert, Button, Col, Form, Row, Table } from 'react-bootstrap'
import { HEADER_CONTENT_TYPE_JSON, SERVICES } from 'utils/constants'
import api from '../../api'

const ListRequested = (props) => {
  const [showError, setShowError] = useState(false)
  const [error, setError] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)
  const [success, setSuccess] = useState("")
  const [onCalls, setOnCalls] = useState([])

  const scrollToBottom = () => {
    document.body.scrollIntoView();
  };

  const initialized = () => {
    setError("")
    setSuccess("")
    setShowError(false)
    setShowSuccess(false)
  }
  
  useEffect(() => {
    findRequested()
  // eslint-disable-next-line
  }, [])

  const findRequested = async() => {
    try {
      let url = SERVICES.ONCALL_LIST_REQUESTED;
      if (props.isAdmin === "true") {
        url = SERVICES.ONCALL_ADMIN_LIST_REQUESTED;
      }
      await api.get( url, HEADER_CONTENT_TYPE_JSON )
      .then(response => {
        setOnCalls(response?.data)
      })
    } catch (error) {
      setError(error.response?.data?.message)
      setShowError(true)
      scrollToBottom()
      return;
    }
  }

  const eventApprove = async(id, e) => {
    e.preventDefault();
    initialized()

    try {
      await api.patch( SERVICES.ONCALL + "/" + id + SERVICES.ONCALL_APPROVE, HEADER_CONTENT_TYPE_JSON )
      .then(response => {
          setSuccess("Lançamento aprovado com sucesso.")
          setShowSuccess(true)
          setOnCalls(onCalls.filter((v, i) => v.id !== id))
          scrollToBottom()
      })
    } catch (error) {
      setError(error.response?.data?.message)
      setShowError(true)
      scrollToBottom()
      return;
    }
  }

  const eventUnapprove = async(id, e) => {
    e.preventDefault();
    initialized()

    try {
      await api.patch( SERVICES.ONCALL + "/" + id + SERVICES.ONCALL_UNAPPROVE, HEADER_CONTENT_TYPE_JSON )
      .then(response => {
          setSuccess("Lançamento reprovado com sucesso.")
          setShowSuccess(true)
          setOnCalls(onCalls.filter((v, i) => v.id !== id))
          scrollToBottom()
      })
    } catch (error) {
      setError(error.response?.data?.message)
      setShowError(true)
      scrollToBottom()
      return;
    }
  }

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="approveAlert">
        <Col sm="12">
          <Alert key='danger' variant='danger' show={showError}>
            {error}
          </Alert>
          <Alert show={showSuccess} variant="success">                
            <Alert.Heading>{success}</Alert.Heading>
          </Alert>
        </Col>
      </Form.Group>
      {props.showListRequested && onCalls.length > 0 ? 
        (
          <Table striped>        
            <thead>
              <tr>
                <th>Colaborador</th>
                <th>Data</th>
                <th>Status</th>
                <th>Descrição</th>
                {props.isAdmin === "true" ? (<th>Aprovador</th>) : (<></>)}
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {onCalls.map(value => (
                <tr key={value.id}>
                  <td>{value['worked-by']}</td>
                  <td>{value['oncall-date']}</td>
                  <td>{value.status}</td>
                  <td>{value['desc-type-oncall']}</td>
                  {props.isAdmin === "true" ? (<td>{value['approved-by']}</td>) : (<></>)}
                  <td>
                    <Button variant="success" type="button" onClick={e => eventApprove(value.id, e)}>Aprovar</Button>
                    &nbsp;&nbsp;
                    <Button variant="danger" type="button" onClick={e => eventUnapprove(value.id, e)}>Reprovar</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : 
        (<></>)
      }
      {props.showListRequested && onCalls.length < 1 ? 
        (
          <Form.Group>
            <Alert variant="success">                
              <Alert.Heading>Nenhum lançamento para ser aprovado</Alert.Heading>
            </Alert>
          </Form.Group>
        ) : 
        (<></>)
      }
    </>
  )
}

export default ListRequested