import InputMask from 'react-input-mask'

function DateInput(props) {
  const mask = 'dD/mM/YYYY';
  const formatChars = {
    'Y': '[0-9]',
    'd': '[0-3]',
    'D': '[0-9]',
    'm': '[0-1]',
    'M': '[0-9]'
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    const { value } = newState;

    if (value === "") {
      return {value, selection: newState.selection};
    }

    const dateParts = value.split('/');
    const dayPart = dateParts[0];
    const monthPart = dateParts[1];    
    
    if(dayPart.startsWith('3'))
      formatChars['D'] = '[0-1]';
    else if(dayPart.startsWith('0'))
      formatChars['D'] = '[1-9]';
    else
      formatChars['D'] = '[0-9]';
    
    if(monthPart.startsWith('1'))
      formatChars['M'] = '[0-2]';
    else
      formatChars['M'] = '[1-9]';

    return {value, selection: newState.selection};
  }

  return (
   <InputMask
      mask={mask}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      formatChars={formatChars}
      beforeMaskedValueChange={beforeMaskedValueChange}
      className={props.className}
      id={props.id}
      placeholder={props.placeholder}
      required={props.required}
    />
  );
}

export default DateInput