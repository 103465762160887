import React from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { FormEvent } from "react";

interface SearchComponentProps {
    onSubmitSearch: () => void,
    onSearchValueChanged: (value?: string) => void,
    value?: string,
    className?: string,
    placeholder?:string,
    disabled?: boolean
}

export function SearchComponent(props: SearchComponentProps) {
    const onSubmitForm = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSubmitSearch();
    };

    const onClearSearch = () => {
        props.onSearchValueChanged("");
    }

    return (
        <div className={props.className}>
            <Form onSubmit={onSubmitForm}>
                <InputGroup>
                    <Form.Control
                        placeholder={props?.placeholder}
                        aria-label={props?.placeholder}
                        value={props.value}
                        onChange={(event) => props.onSearchValueChanged(event.target.value)} disabled={props.disabled}/>
                    <Button variant="outline-secondary" onClick={onClearSearch} disabled={props.disabled}>
                        Limpar
                    </Button>
                    <Button variant="secondary" type="submit"  disabled={props.disabled}>
                        Pesquisar
                    </Button>
                </InputGroup>
            </Form>
        </div>
    );
}