import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Collaborator } from "../List/CollaboratorsService";
import { useState } from "react";
import { CollaboratorsHeaderTitle, CollaboratorsHeaderTitleSingle } from "../components/CollaboratorsHeaderTitle";
import CollaboratorsContainer from "../components/CollaboratorsContainer";
import BackButton from "pages/Team/components/BackButton";
import DeleteCollaboratorModal from "pages/Team/Edit/components/DeleteCollaboratorModal";
import EditTeamService from "pages/Team/Edit/EditTeamService";
import { AxiosError } from "axios";
import { CollaboratorsnFeedback } from "../components/CollaboratorsnFeedback";
import CollaboratorDetail from "./CollaboratorDetail";
import SelectTeamModal from "./SelectTeamModal";
import { TeamItem } from "../components/TeamsToSelectList";
import { Team } from "pages/Team/TeamService";
import AddTeamModal from "../components/AddTeamModal";

interface DeleteCollaboratorModal {
    show: boolean,
    collaboratorSelected: Collaborator
}

interface AddTeamModal {
    team: Team | undefined,
    show: boolean,
}

interface SelectTeamModal {
    show: boolean,
    onCloseModal: () => void
}

function CollaboratorsDetail() {
    const navigate = useNavigate()
    const location = useLocation()
    const { collaborator } = location.state
    const [deleteCollaboratorModal, setDeleteCollaboratorModal] = useState<DeleteCollaboratorModal>();
    const [addTeamModal, setAddTeamModal] = useState<AddTeamModal>();
    const [selectTeamModal, setSelectTeamModal] = useState<SelectTeamModal>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [updateScreen, setUpdateScreen] = useState<number>(0);

    const initialCollaborator = {
        id: collaborator.id,
        name: collaborator.name,
        username: collaborator.username,
        role: collaborator.role,
        imageUrl: collaborator.imageUrl,
        occupation: collaborator.occupation,
        teams: collaborator.teams
    }

    const removeCollaboratorToTeam = (_: Collaborator, date: string) => {
        const service = new EditTeamService()
        setLoading(true)
        setDeleteCollaboratorModal(undefined)
        service.removeUserToTeam(collaborator.teams[0].id, collaborator.id, date)
            .then((response: any) => {
                collaborator.teams = []
                setUpdateScreen(prevState => prevState + 1);
            })
            .catch((error: AxiosError | any) => {
                const errorMessage = error?.response?.data?.message
                setFeedback(
                    CollaboratorsnFeedback.createFeedback(
                        "Erro ao remover colaborador",
                        errorMessage ?? "Tivemos um problema ao remover o colaborador. Por favor, tente novamente."
                    )
                )
            })
            .finally(() => setLoading(false))
    }

    const onClickToRemoveCollaborator = () => {
        setDeleteCollaboratorModal({
            show: true,
            collaboratorSelected: collaborator
        })
    }

    const onClickToAddCollaborator = () => {
        setSelectTeamModal({
            show: true,
            onCloseModal() { },
        }
        )
    }

    const addCollaboratorToTeam = (team: Team, date: string) => {
        setAddTeamModal(undefined)
        const service = new EditTeamService()
        setLoading(true)
        service.addUserToTeam(team.id, collaborator.username, date)
            .then((response: any) => {
                collaborator.teams.push(team)
                setUpdateScreen(prevState => prevState + 1);
            })
            .catch((error: AxiosError | any) => {
                const errorMessage = error?.response?.data?.message
                setFeedback(
                    CollaboratorsnFeedback.createFeedback(
                        "Erro ao adicionar colaborador",
                        errorMessage ?? "Tivemos um problema ao adicionar o colaborador. Por favor, tente novamente."
                    )
                )
            })
            .finally(() => setLoading(false))
    }

    const teamSelected = (team: TeamItem) => {
        setSelectTeamModal(undefined)
        setAddTeamModal({
            team: team,
            show: true,
        })
    }

    const [formCollaborator, setFormCollaborator] = useState<Collaborator>(initialCollaborator);

    return (<Container>
        <CollaboratorsContainer>
            <CollaboratorsHeaderTitleSingle
                title="Detalhes Colaborador" />
            <BackButton onClick={() => navigate(-1)}
            />
            <CollaboratorDetail
                key={updateScreen}
                collaborator={collaborator}
                isButtonVisible={collaborator.teams?.length > 0}
                onClickToAddCollaborator={onClickToAddCollaborator}
                onClickToRemoveCollaborator={onClickToRemoveCollaborator}
            />
        </CollaboratorsContainer>

        <DeleteCollaboratorModal
            onCloseModal={() => setDeleteCollaboratorModal(undefined)}
            collaborator={deleteCollaboratorModal?.collaboratorSelected}
            show={deleteCollaboratorModal?.show ?? false}
            onSubmitValidForm={removeCollaboratorToTeam}
        />
        <SelectTeamModal
            show={selectTeamModal?.show ?? false}
            onCloseModal={() => setSelectTeamModal(undefined)}
            onItemClicked={(item: TeamItem) => { teamSelected(item) }}
        />

        <AddTeamModal
            onCloseModal={() => setAddTeamModal(undefined)}
            team={addTeamModal?.team}
            show={addTeamModal?.show ?? false}
            onSubmitValidForm={addCollaboratorToTeam}
        />

    </Container >
    );
};
export default CollaboratorsDetail
function setConfirmationModal(arg0: any) {
    throw new Error("Function not implemented.");
}

function setFeedback(arg0: any) {
    throw new Error("Function not implemented.");
}

