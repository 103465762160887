/* istanbul ignore file */
export const getItem = item => sessionStorage.getItem(item) || ''

export const getItemArray = item => sessionStorage.getItem(item) || []

export const setItem = (name, data) => sessionStorage.setItem(name, data)

export const authToken = () => getItem('authToken')

export const permissions = () => getItemArray('permissions')

export const setAuthToken = (token = '') => setItem('authToken', token)

export const setPermissions = (permissions = []) => setItem('permissions', permissions)

export const clearAuthToken = () => sessionStorage.removeItem('authToken')

export const clearPermissions = () => sessionStorage.removeItem('permissions')
