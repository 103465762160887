import React, { useState } from "react"
import { Alert, Button, Col, Form, Row } from "react-bootstrap"
import { HEADER_CONTENT_TYPE_JSON, SERVICES } from "utils/constants"
import api from "../../api"
import CollaboratorHour from "./collaboratorHour"

function HourException() {
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const initialized = () => {
    setError('')
    setSuccess('')
    setShowError(false)
    setShowSuccess(false)
  }

  const sync = async (e) => {
    e.preventDefault();
    initialized()
    try {
      await api.get(SERVICES.SYNC_HOUR_EXCEPTION, HEADER_CONTENT_TYPE_JSON).then(response => {
        setSuccess(response?.data?.message)
        setShowSuccess(true)
      })
    } catch (error) {
      setError(error.response?.data?.message)
      setShowError(true)
      return;
    }
  }

  return (    
    <Form>
      <Form.Group as={Row} className="mb-3" controlId="syncException">
        <Form.Label column sm="11"> Sincronizar Lançamentos de Ausência e Extra com o GP </Form.Label>
        <Col sm="1">
          <Button variant="success" type="button" onClick={e => [setShowError(false), setShowSuccess(false), setError(''), setSuccess(''), sync(e)]}> Sincronizar </Button>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="exceptionAlert">
        <Col sm="12">
          <Alert key='danger' variant='danger' show={showError}>
            {error}
          </Alert>
          <Alert show={showSuccess} variant="success">                
            <Alert.Heading>{success}</Alert.Heading>
          </Alert>
        </Col>
      </Form.Group>
      <CollaboratorHour />
    </Form>
  )

}

export default HourException