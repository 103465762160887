import { useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { session } from 'utils'
import { HEADER_CONTENT_TYPE_JSON, PERMISSIONS, SERVICES } from 'utils/constants'
import api from '../../api'
import ListApproved from './listApproved'
import ListRequested from './listRequested'

const OnCall = () => {
  const [collaboratorLogin, setCollaboratorLogin] = useState('')
  const [showError, setShowError] = useState(false)
  const [error, setError] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)
  const [showListRequested, setShowListRequested] = useState(true)
  const [showListApproved, setShowListApproved] = useState(false)

  const scrollToBottom = () => {
    document.body.scrollIntoView();
  };

  const allowAccess = async (e) => {
    e.preventDefault();

    try {
      await api.patch(SERVICES.ONCALL_ALLOW_LAUNCH + "/" + collaboratorLogin); 
      setShowSuccess(true)
      setShowError(false)
      scrollToBottom()
    } catch (error) {
      setError(error.response?.data?.message);
      setShowError(true)
      setShowSuccess(false)
      scrollToBottom()
      return;
    }
  }

  const allowPermissions = async (e) => {
    e.preventDefault();

    try {
      await api.get(SERVICES.SYNC_ONCALL_PERMISSIONS, HEADER_CONTENT_TYPE_JSON); 
      setShowSuccess(true)
      setShowError(false)
      scrollToBottom()
    } catch (error) {
      setError(error.response?.data?.message);
      setShowError(true)
      setShowSuccess(false)
      scrollToBottom()
      return;
    }
  }

  const allowLaunch = async (e) => {
    e.preventDefault();

    try {
      await api.get(SERVICES.SYNC_ONCALL_LAUNCHERS, HEADER_CONTENT_TYPE_JSON); 
      setShowSuccess(true)
      setShowError(false)
      scrollToBottom()
    } catch (error) {
      setError(error.response?.data?.message);
      setShowError(true)
      setShowSuccess(false)
      scrollToBottom()
      return;
    }
  }

  return (
    <Form>      
      {session.permissions().includes(PERMISSIONS.oncall_approver) ? 
        (
          <>
            <Form.Group as={Row} className="mb-3" controlId="showLists">
              <Col sm="12">
                <Button variant="warning" type="button" onClick={e => [setShowError(false), setShowSuccess(false), setError(''), setShowListRequested(true), setShowListApproved(false)]}> À Aprovar </Button>
                &nbsp;&nbsp;
                <Button variant="success" type="button" onClick={e => [setShowError(false), setShowSuccess(false), setError(''), setShowListRequested(false), setShowListApproved(true)]}> Aprovados </Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="showAlertApprover">
              <Col sm="12">
                <Alert key='danger' variant='danger' show={showError}>
                  {error}
                </Alert>
                <Alert show={showSuccess} variant="success">                
                  <Alert.Heading>Solicitação concluída com sucesso.</Alert.Heading>
                </Alert>
              </Col>
            </Form.Group>
            <Form.Group>
              <ListRequested showListRequested={showListRequested} isAdmin="false"/>
              <ListApproved showListApproved={showListApproved} />
            </Form.Group>
          </>
        ) : 
        (
          <>
            <Form.Group as={Row} className="mb-3" controlId="allowLunch">
              <Form.Label column sm="6"> Liberar lançamento para colaborador: </Form.Label>
              <Col sm="5">
                <Form.Control type="text" placeholder="Login do colaborador"
                  onChange={(e) => [setCollaboratorLogin(e.target.value)]} 
                />
              </Col>
              <Col sm="1">
                <Button variant="success" type="button" onClick={e => [setShowError(false), setShowSuccess(false), setError(''), allowAccess(e)]}> Liberar </Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="syncPermissions">
              <Form.Label column sm="11"> Sincronizar permissões do GP: </Form.Label>
              <Col sm="1">
                <Button variant="success" type="button" onClick={e => [setShowError(false), setShowSuccess(false), setError(''), allowPermissions(e)]}> Permissões </Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="syncLaunch">
              <Form.Label column sm="11"> Sincronizar lançamentos com o GP: </Form.Label>
              <Col sm="1">
                <Button variant="success" type="button" onClick={e => [setShowError(false), setShowSuccess(false), setError(''), allowLaunch(e)]}> Lançamentos </Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="showAlertAdmin">
              <Col sm="12">
                <Alert key='danger' variant='danger' show={showError}>
                  {error}
                </Alert>
                <Alert show={showSuccess} variant="success">                
                  <Alert.Heading>Solicitação concluída com sucesso.</Alert.Heading>
                </Alert>
              </Col>
            </Form.Group>
            <Form.Group>
              <ListRequested showListRequested={showListRequested} isAdmin="true"/>
            </Form.Group>
          </>
        )
      }  
    </Form>
  )
}

export default OnCall