import DateInput from "components/DateInput"
import TimeInput from "components/TimeInput"
import React, { useEffect, useState } from "react"
import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap"
import { HEADER_CONTENT_TYPE_JSON, SERVICES } from "utils/constants"
import api from "../../api"

function Events() {
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [events, setEvents] = useState([])
  const [hasEvents, setHasEvents] = useState(false)
  const [title, setTitle] = useState("")
  const [speakers, setSpeakers] = useState("")
  const [description, setDescription] = useState("")
  const [tags, setTags] = useState("")
  const [begin, setBegin] = useState("")
  const [hourInitial, setHourInitial] = useState("")
  const [hourEnd, setHourEnd] = useState("")
  const [duration] = useState("1h")
  const [quantity] = useState("1000")
  
  useEffect(() => {
    findEvents()
  // eslint-disable-next-line
  }, [])

  const initialized = () => {
    setError("")
    setSuccess("")
    setShowError(false)
    setShowSuccess(false)
  }

  const create = async(e) => {
    e.preventDefault();
    initialized()

    try {
      await api.post(
        SERVICES.EVENTS, JSON.stringify({
          title,
          speakers, 
          description, 
          tags, 
          begin, 
          hourInitial, 
          hourEnd,
          duration,
          quantity
        }),
        HEADER_CONTENT_TYPE_JSON
      ).then(response => {
          setSuccess(response?.data?.message)
          setShowSuccess(true)
          findEvents()
      })
    } catch (error) {
      setError(error.response?.data?.message)
      setShowError(true)
      return;
    }
  }

  const findEvents = async() => {
    initialized()

    try {
      setEvents([])
      await api.get(SERVICES.EVENTS_LIST, HEADER_CONTENT_TYPE_JSON).then(res => {
        setEvents(res?.data)
        setHasEvents(true)
      })
    } catch (error) {
      setError(error.response?.data?.message)
      setShowError(true)
      return;
    }
  }

  const eventDelete = async (id, e) => {
    e.preventDefault();
    initialized()

    try {
      await api.delete(SERVICES.EVENTS + "/" + id, HEADER_CONTENT_TYPE_JSON); 
      setEvents(events.filter((v, i) => v.id !== id));
    } catch (error) {
      setError(error.response?.data?.message);
      setShowError(true)
      return;
    }
  }

  return (
    <Form>
      <Form.Group as={Row} className="mb-3" controlId="eventData1">
        <Form.Label column sm="2"> Titulo: </Form.Label>
        <Col sm="4">
          <Form.Control type="text" placeholder="Titulo" onChange={(e) => [setTitle(e.target.value)]} />
        </Col>
        <Form.Label column sm="2"> Palestrante: </Form.Label>
        <Col sm="4">
          <Form.Control type="text" placeholder="Palestrante" onChange={(e) => [setSpeakers(e.target.value)]} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="eventData2">
        <Form.Label column sm="2"> Descrição: </Form.Label>
        <Col sm="4">
          <Form.Control type="text" placeholder="Descrição" onChange={(e) => [setDescription(e.target.value)]} />
        </Col>
        <Form.Label column sm="2"> Tipo Apresentação: </Form.Label>
        <Col sm="4">
          <Form.Select aria-label="Default select example" onChange={(e) => [setTags(e.target.value)]}>
            <option>Selecione</option>
            <option value="Back">BackEnd</option>
            <option value="Front">FrontEnd</option>
            <option value="iOS">iOS</option>
            <option value="Android">Android</option>
            <option value="DevOps/SRE">DevOps/SRE</option>
            <option value="Qualidade">Qualidade</option>
            <option value="Negocios">Negocios</option>
            <option value="Diversidade">Diversidade</option>
            <option value="Agilidade">Agilidade</option>
            <option value="UX">UX</option>
            <option value="People Talks">People Talks</option>
            <option value="Lets Talk">Let's Talk</option>
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="eventData3">
        <Form.Label column sm="2"> Data Evento: </Form.Label>
        <Col sm="2">
          <Form.Control required as={DateInput} mask="99/99/9999" placeholder="dd/MM/aaaa" onChange={(e) => [setBegin(e.target.value)]} />
        </Col>
        <Form.Label column sm="2"> Hora Início: </Form.Label>
        <Col sm="2">
          <Form.Control required as={TimeInput} mask="99:99" placeholder="HH:mm" onChange={(e) => [setHourInitial(e.target.value)]} />
        </Col>
        <Form.Label column sm="2"> Hora Fim: </Form.Label>
        <Col sm="2">
          <Form.Control required as={TimeInput} mask="99:99" placeholder="HH:mm" onChange={(e) => [setHourEnd(e.target.value)]} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="eventData3">
        <Button variant="primary" type="button" onClick={e => [setShowError(false), setShowSuccess(false), setError(''), setSuccess(''), create(e)]}>Criar Evento</Button>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="eventsAlert">
        <Col sm="12">
          <Alert key='danger' variant='danger' show={showError}>
            {error}
          </Alert>
          <Alert show={showSuccess} variant="success">                
            <Alert.Heading>{success}</Alert.Heading>
          </Alert>
        </Col>
      </Form.Group>
      {hasEvents ? (
        <Table striped>        
          <thead>
            <tr>
              <th>ID</th>
              <th>Titulo</th>
              <th>Mensagem</th>
              <th>Data Evento</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {events.map(value => (
              <tr key={value.id}>
                <td>{value.id}</td>
                <td>{value.title}</td>
                <td>{value.description}</td>
                <td>{value.begin}</td>
                <td><Button variant="danger" type="button" onClick={e => eventDelete(value.id, e)}>Excluir</Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (<></>)}
    </Form>
  )
}

export default Events