import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const ItemContainer = styled.div`
    width: fit-content;
    padding: 5px 15px;
    color: ${props => props.selected ? "white" : "black"};
    border: 1px solid lightgray;
    border-radius: 10px;
    background-color: ${props => props.selected ? "blue" : "white"};;
    cursor: pointer;
`

export const PageSelector = ({
    onSelectPage,
    numberOfPages,
    pageSelected
}) => {
    let numberOfItemsToShow = 5

    let arrayBegin = Math.max(pageSelected - 2, 1)
    let arrayEnd = Math.min(arrayBegin + numberOfItemsToShow, numberOfPages + 1)
    numberOfItemsToShow = arrayEnd - arrayBegin

    return (
        <Row className="m-4">
            <Container className="d-flex justify-content-center">
                <Row className="g-2">
                    {Array.from({ length: numberOfItemsToShow }, (_, i) => i + arrayBegin - 1).map((number) =>
                        <Col onClick={() => onSelectPage(number + 1)} key={number}>
                            <ItemContainer selected={Number(pageSelected) === number + 1}>{number + 1}</ItemContainer>
                        </Col>
                    )}
                </Row>
            </Container>
        </Row>
    );
}