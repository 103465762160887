import React from "react";
import "./EvidenceStatus.css";

const EvidenceStatus = ({status, description}) => {
  const statusStyle = () => {
    switch (status.toLowerCase()) {
      case "approved":
        return "evidence-status-approved";
      case "unapproved":
        return "evidence-status-reproved";
      default:
        return "evidence-status-waiting";
    }
  };

  return <div className={"evidence-status " + statusStyle()}>{description}</div>;
};

export default EvidenceStatus;
