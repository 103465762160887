import EmptyState from "components/EmptyState";
import { useState, useEffect } from "react";
import EmptyStateImage from "images/empty_box.svg";
import { SERVICES, URL_PAGES } from "utils/constants";
import Loading from "components/Loading";
import api from "api";
import { Alert, Button, Container, Row, Table, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const EscalationList = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const { team } = location.state
    const [isLoading, setLoading] = useState();
    const [escalations, setEscalations] = useState();

    const hasNothingToShow = () => {
        let escalationSize = escalations?.length ?? 0
        return escalationSize === 0
    }

    const getEscalation = async () => {
        try {
            setLoading(true)
            const response = await api.get(SERVICES.ESCALATION_LIST(team.id))
            setEscalations(response.data)
        } catch (error) {
            console.log(error)
            alert("Houve um erro, tente novamente!")
        } finally {
            setLoading(false)
        }
    };

    const onDeleteEscalation = (item) => {
        setLoading(true)
        api.delete(SERVICES.ESCALATION + `/${item.id}`)
            .then(() => getEscalation())
            .catch(() => alert("Houve um erro ao deletar, tente novamente!"))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getEscalation()
    }, [])


    if (isLoading) {
        return <Loading isLoading={true} />
    }

    let componentList
    if (hasNothingToShow()) {
        componentList = (
            <EmptyState
                message={"Não há nenhuma escalation para este time."}
                img={EmptyStateImage} />
        );
    } else {
        componentList = (
            <Row>
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th>Ordenação</th>
                            <th>Coordenador 1</th>
                            <th>Coordenador 2</th>
                            <th>Pode aprovar?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {escalations.map((item, index) =>
                            <tr key={index}>
                                <td>{item.ordination}</td>
                                <td>{item.loginCoord1}</td>
                                <td>{item.loginCoord2 ?? "N/A"}</td>
                                <td>{item.approver ? `Sim` : `Não`}</td>
                                <td>
                                    <Button className="m-1" variant="outline-danger" onClick={() => onDeleteEscalation(item)}>Excluir</Button>
                                    {/* TODO it will be implemented soon
                                    <Link to={`${URL_PAGES.ESCALATION_LIST}/${item.id}`} className="m-1">
                                        <Button variant="outline-primary">Editar</Button>
                                    </Link> 
                                    */}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Row>
        );
    }

    return (
        <Container>
            <Alert variant="info">
                <Alert.Heading>Visualizando escalation do time: {team.name}</Alert.Heading>
            </Alert>
            <Col>
                <Button variant="outline-secondary" className="m-1" onClick={() => navigate(-1)}>Voltar</Button>
                <Button className="m-1" onClick={() => navigate(URL_PAGES.ESCALATION_CREATION, { state: { team: team } })}>Criar escalation</Button>
            </Col>
            {componentList}
        </Container>
    );
}

export default EscalationList;