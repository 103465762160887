import React from "react";
import TeamListItem from "./TeamListItem";
import { Button, Container, Row, Col } from "react-bootstrap";
import EmptyState from "components/EmptyState";
import EmptyStateImage from "images/empty_box.svg";
import { SearchComponent } from "../../components/SearchComponent";

export interface TeamItem {
    id: number,
    name: string,
    image?: string
}

interface TeamListProps {
    teams?: TeamItem[];
    onItemClicked?: (team: TeamItem) => void,
    showEmptyState: boolean,
    onSubmitSearch: () => void,
    onSearchValueChanged: (value?: string) => void,
    onCreateTeam: () => void,
    onBackPressed: () => void,
    searchValue?: string
}

export function TeamList(props: TeamListProps) {
    return (
        <Container>
            <Row className="g-4">
                <Col sm={5} lg={6}>
                    <div className="row g-3">
                        <Col lg={2}>
                            <Button variant="outline-primary" onClick={props.onBackPressed}>
                                Voltar
                            </Button>
                        </Col>
                        <Col lg={4}>
                            <Button variant="primary" onClick={props.onCreateTeam}>
                                Criar novo time
                            </Button>
                        </Col>
                    </div>
                </Col>
                <SearchComponent
                    placeholder="Pesquisar time"
                    className="col-md-6"
                    value={props.searchValue}
                    onSubmitSearch={props.onSubmitSearch}
                    onSearchValueChanged={props.onSearchValueChanged}
                />
            </Row>
            <Row>
                {props.teams?.map((item) =>
                    <TeamListItem
                        key={item.id}
                        team={item}
                        buttonLabel="Editar time"
                        onClick={(item: TeamItem) => { props?.onItemClicked && props?.onItemClicked(item) }}
                    />
                ) ?? <EmptyState
                        className="mt-3"
                        message={"Nenhum time encontrado..."}
                        img={EmptyStateImage}
                    />
                }
            </Row>
        </Container>
    );
}