import EmptyState from "components/EmptyState";
import TeamListItem from "pages/Team/List/components/TeamListItem";
import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import EmptyStateImage from "images/empty_box.svg";
import TeamsToSelectItem from "./TeamsToSelectItem";

export interface TeamItem {
    id: number,
    name: string,
    image?: string
}

interface TeamListProps {
    teams?: TeamItem[],
    searchValue?: string,
    onItemClicked?: (team: TeamItem) => void,
}

export default function TeamsToSelectList(props: TeamListProps) {
    return (
        <Container>
          
            <Row>
                {props.teams?.map((item) =>
                    <TeamsToSelectItem
                        key={item.id}
                        team={item}
                        buttonLabel="Selecionar"
                        onClick={(item: TeamItem) => { props?.onItemClicked && props?.onItemClicked(item) }}
                    />
                ) ?? <EmptyState
                        className="mt-3"
                        message={"Nenhum time encontrado..."}
                        img={EmptyStateImage}
                    />
                }
            </Row>
        </Container>
    );
}