import { Row, Col, Button } from "react-bootstrap"
import styled from "styled-components"
import { Invoice, InvoiceStatus, InvoiceType } from "../hooks/useInvoiceService";
import { useRef } from "react"

interface InvoiceItemProps {
    invoice: Invoice,
    onFileSelected: (invoice: Invoice, file: File | null) => void
}

const typeLabel = {
    [InvoiceType.MONTHLY]: "Nota mensal",
    [InvoiceType.COMPLEMENT]: "Nota complementar",
};

const statusParamsByInvoiceStatus = {
    [InvoiceStatus.GENERATING_PAYMENT]: {
        label: "Aguardando envio do espelho",
        color: '#7c7c7c'
    },
    [InvoiceStatus.WAITING_UPLOAD]: {
        label: "Aguardando upload",
        color: '#0d15b0'
    },
    [InvoiceStatus.UPLOADED]: {
        label: "Upload concluído",
        color: '#38b00d'
    },
    [InvoiceStatus.APPROVED]: {
        label: "Nota fiscal aprovada",
        color: '#0ca52a'
    },
    [InvoiceStatus.INVALID]: {
        label: "Rejeitado, por favor envie novamente",
        color: '#d00a0a'
    },
};

const clickableStatuses = new Set([InvoiceStatus.WAITING_UPLOAD, InvoiceStatus.INVALID]);

const ItemContainer = styled(Col)`
    height: 100%;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 30px 0px;
    text-align: center;
    :hover {
        background-color: ${props => props.clickable ? "#e7e7e7" : "white"};
        cursor: ${props => props.clickable ? "pointer" : "default"};
    }
`

const StatusLabel = styled.p`
    color: white;
    border-radius: 5px;
    width: fit-content;
    margin: 0 auto;
    background-color: ${props => props.color};
`

const textStyles = {
    title: {
        fontSize: 30,
        fontWeight: "bold",
    },
    subTitle: {
        fontSize: 20,
        marginTop: 4,
    },
    observation: {
        fontSize: 15,
        marginTop: 5
    }
};

export default function InvoiceUploadItem(props: InvoiceItemProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const statusParams = statusParamsByInvoiceStatus[props.invoice.status as InvoiceStatus]
    const isItemClickable = clickableStatuses.has(props.invoice.status as InvoiceStatus)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onFileSelected(props.invoice, event.target.files?.[0] || null);
    };

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <Col sm={12} md={6}>
            <ItemContainer clickable={isItemClickable}>
                <Row className="g-3 p-2">
                    <div style={textStyles.title}>{typeLabel[props.invoice.type as InvoiceType]}</div>
                    <div style={textStyles.subTitle}>Competência: {props.invoice.competence}</div>
                    {statusParams?.label && <StatusLabel color={statusParams?.color} className="mt-2">{statusParams?.label}</StatusLabel>}
                    {props.invoice.observation && <div style={textStyles.observation}>Observação: {props.invoice.observation}</div>}
                    {isItemClickable && <div className="mt-3">
                        <Button onClick={handleButtonClick} variant="outline-primary">Fazer upload</Button>
                    </div>}
                    <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
                </Row>
            </ItemContainer>
        </Col>
    )
}