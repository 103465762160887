/* istanbul ignore file */
import axios from 'axios';

import { session, constants } from '../utils';

const {
	DEFAULT_DEVICE,
	DEFAULT_APP_VERSION,
	DEFAULT_DEVICE_TYPE
} = constants;

const instance = axios.create({
	// eslint-disable-next-line no-undef
	baseURL: process.env.REACT_APP_API,
	timeout: 33000
});

instance.interceptors.request.use(config => ({
	...config,
	headers: {
		...config.headers,
		authToken: session.authToken(),
		app_version: DEFAULT_APP_VERSION,
		device_type: DEFAULT_DEVICE_TYPE,
		device_model: DEFAULT_DEVICE,
		device_os: DEFAULT_DEVICE
	}
}), error => Promise.reject(error));

instance.interceptors.response.use(response => response, error => {	
	return Promise.reject(error);
});

export default instance;
