import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import api from "api";
import { SERVICES } from "utils/constants";
import { HEADER_CONTENT_TYPE_JSON } from "utils/constants";
import "./EvidenceDetail.css";
import { useReducer } from "react";
import Loading from "components/Loading";
import EasyFeedbackModal from "components/EasyFeedbackModal";

class ManagementActionType {
  static Approve = new ManagementActionType(
    SERVICES.MANAGEMENT_EVIDENCE_APPROVE
  );
  static Reprove = new ManagementActionType(
    SERVICES.MANAGEMENT_EVIDENCE_REPROVE
  );

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  getEndpoint(evidenceId) {
    return this.endpoint(evidenceId);
  }
}

const initialState = {
  input: "",
  isInputValid: true,
  formValid: false,
  loading: false,
  wasManagedSuccesfully: false,
  feedbackFailure: null,
};

const reducerActions = {
  reset_params: (__, _) => {
    return initialState;
  },
  invalid_field: (state, _) => {
    return { ...state, isInputValid: false, formatValid: false };
  },
  valid_fields: (state, _) => {
    return { ...state, isInputValid: true, formatValid: true };
  },
  update_value: (state, action) => {
    return { ...state, input: action.value, isInputValid: true };
  },
  starting_management: (state, _) => {
    return { ...state, loading: true };
  },
  feedback_modal_close_pressed: (state, _) => {
    return { ...state, feedbackFailure: null };
  },
  management_success: (state, _) => {
    return {
      ...state,
      loading: false,
      wasManagedSuccesfully: true,
    };
  },
  management_failure: (state, action) => {
    return {
      ...state,
      loading: false,
      feedbackFailure: {
        description: action.error ?? "Houve um erro, tente novamente!",
      },
    };
  },
};

function reduce(state, action) {
  let reducerFunction = reducerActions[action.type];
  if (reducerFunction) {
    return reducerFunction(state, action);
  }
  console.log("[WARNING] Action without a reducer:", action);
}

const EvidenceDetail = ({ show, evidence, onClose, onManagementSuccess }) => {
  const [detailState, dispatch] = useReducer(reduce, initialState);

  const dismiss = () => {
    dispatch({ type: "reset_params" });
    onClose();
  };

  const startManagementRequest = async (managementType) => {
    try {
      dispatch({ type: "starting_management" });
      const body = {
        description: detailState.input,
      };
      await api.patch(
        managementType.getEndpoint(evidence.id),
        JSON.stringify(body),
        HEADER_CONTENT_TYPE_JSON
      );
      dispatch({ type: "management_success" });
      onManagementSuccess();
    } catch (error) {
      dispatch({
        type: "management_failure",
        error: error.response?.data?.message,
      });
    }
  };

  const validateForm = (event) => {
    event.preventDefault();
    const form = document.getElementsByTagName("form")[0];

    if (form.checkValidity() === false) {
      dispatch({ type: "invalid_field" });
      event.stopPropagation();
      return false;
    }

    dispatch({ type: "valid_fields" });
    return true;
  };

  const onClickToReproveForm = (event) => {
    const isFormValid = validateForm(event);
    if (isFormValid) {
      startManagementRequest(ManagementActionType.Reprove);
    }
  };

  const onClickToApproveForm = (event) => {
    const isFormValid = validateForm(event);
    if (isFormValid) {
      startManagementRequest(ManagementActionType.Approve);
    }
  };

  if (!show) {
    return <></>;
  }

  if (detailState.loading) {
    return <Loading isLoading={detailState.loading} />;
  }

  if (detailState.wasManagedSuccesfully) {
    return (
      <EasyFeedbackModal
        show={true}
        title={"Analisado com sucesso!"}
        description={"A análise foi feita com sucesso, pressione para voltar."}
        listener={() => dismiss()}
      />
    );
  }

  if (detailState.feedbackFailure) {
    return (
      <EasyFeedbackModal
        show={true}
        title={"Houve um erro!"}
        description={detailState.feedbackFailure.description}
        listener={() => dispatch({ type: "feedback_modal_close_pressed" })}
      />
    );
  }

  return (
    <Modal show={show} onHide={dismiss} dialogClassName="my-modal">
      <Modal.Header closeButton={onClose}>
        <Modal.Title>Detalhes da evidência</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={evidence?.urlUpload} alt="Imagem de upload" />
        <Form noValidate>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Observação:</Form.Label>
            <Form.Control
              required
              as="textarea"
              placeholder="Adicione uma descrição"
              value={detailState.input}
              isInvalid={!detailState.isInputValid}
              onChange={(e) =>
                dispatch({ type: "update_value", value: e.target.value })
              }
            />
            <Form.Control.Feedback type="invalid">
              Campo obrigatório.
            </Form.Control.Feedback>
          </Form.Group>
          <div className="button-container">
            <Button variant="primary" onClick={onClickToApproveForm}>
              Aprovar
            </Button>
            <Button variant="outline-primary" onClick={onClickToReproveForm}>
              Rejeitar
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EvidenceDetail;
