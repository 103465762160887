import React from "react";
import EvidenceItem from "./components/EvidenceItem";
import EmptyState from "components/EmptyState";
import "./EvidenceGrid.css";
import EmptyStateImage from "../../images/empty_box.svg";

const EvidenceGrid = ({ evidences, onClickToManageItem }) => {
  if (!evidences) {
    return <></>;
  }

  if (!evidences.length) {
    return (
      <EmptyState
        message={"Não há evidências para serem mostradas..."}
        img={EmptyStateImage}
      />
    );
  }

  return (
    <>
      <div className="evidence-grid">
        {evidences.map((evidence) => (
          <EvidenceItem
            key={evidence.id}
            item={evidence}
            onClickToManage={onClickToManageItem}
          />
        ))}
      </div>
    </>
  );
};

export default EvidenceGrid;
