import api from "api";
import { SERVICES } from "utils/constants";
import { Collaborator, Team, TeamService } from "../TeamService";

class CollaboratorResponse {
    id: number;
    name: string;
    login: string;
    image?: string;

    constructor(
        id: number,
        name: string,
        login: string,
        imageUrl?: string
    ) {
        this.id = id;
        this.name = name;
        this.login = login;
        this.image = imageUrl;
    }

    mapToCollaborator(): Collaborator {
        return new Collaborator(
            this.id,
            this.name,
            this.login,
            undefined,
            this.image
        )
    }
}

class CollaboratorByTeamResponse {
    'id-collaborator': number;
    name: string;
    login: string;
    role?: string;
    'url-image'?: string;

    constructor(
        id: number,
        name: string,
        login: string,
        role?: string,
        imageUrl?: string
    ) {
        this["id-collaborator"] = id;
        this.name = name;
        this.login = login;
        this.role = role;
        this["url-image"] = imageUrl;
    }
}

class EditTeamRequest {
    id: number;
    'nome': string;
    'descricao'?: string;
    'url-imagem'?: string;
    os?: string

    constructor(
        id: number,
        name: string,
        description?: string,
        imageUrl?: string,
        op?: string
    ) {
        this.id = id
        this['nome'] = name
        this['descricao'] = description
        this["url-imagem"] = imageUrl
        this.os = op
    }
}

export default class EditTeamService {

    async getCollaboratorsByTeam(teamName: string): Promise<Collaborator[]> {
        const getParams = () => {
            let params = {
                'team-name': teamName
            }

            const urlSearchParams = new URLSearchParams(params);
            return urlSearchParams.toString()
        };

        const { data } = await api.get(`${SERVICES.TEAM_WITH_COLLABORATORS}?${getParams()}`)
        let { teams } = data
        if (teams && teams.length > 0) {
            return teams[0].collaborators?.map((collaborator: CollaboratorByTeamResponse) => new Collaborator(
                collaborator["id-collaborator"],
                collaborator.name,
                collaborator.login,
                collaborator.role,
                collaborator["url-image"]
            ));
        }
        return [];
    }

    async getCollaborators(): Promise<Collaborator[]> {
        const { data } = await api.get(SERVICES.USERS_ADMIN)
        return data.map((collaborator: CollaboratorResponse) => new Collaborator(
            collaborator.id,
            collaborator.name,
            collaborator.login,
            undefined,
            collaborator.image
        ))
    }

    async addUserToTeam(teamId: number, username: string, initDate: string): Promise<any> {
        const { data } = await api.patch(SERVICES.TEAM_ADMIN_ADD_USER(teamId), {
            login: username,
            initDate: initDate
        })
        return data
    }

    async removeUserToTeam(teamId: number, userId: number, exitDate: string): Promise<any> {
        const { data } = await api.delete(SERVICES.TEAM_ADMIN_DELETE_USER(teamId, userId, exitDate.replace(/\//g, '')))
        return data
    }

    async editTeam(
        id: number,
        name: string,
        description?: string,
        teamImageUrl?: string,
        op?: string,
        imageSelected?: File,
    ): Promise<Team> {
        const teamService = new TeamService();
        const imageUrl = await teamService.saveImage(name, imageSelected, teamImageUrl)
        const body = new EditTeamRequest(
            id,
            name,
            description,
            imageUrl,
            op
        )
        const { data } = await api.put(SERVICES.TEAM, body)
        return new Team(
            data.id,
            data.nome,
            data.descricao,
            data['url-imagem'],
            data.os
        )
    }

    async deleteTeam(id: number): Promise<any> {
        let { data } = await api.delete(SERVICES.TEAM + `/${id}`)
        return data
    }
}