import React from "react";
import { ToggleButton, ButtonGroup } from "react-bootstrap";

class EasySubMenuItem {
  constructor(id, description, endpoint) {
    this.id = id;
    this.description = description;
    this.endpoint = endpoint;
  }
}

const EasySubMenuButton = ({ onClick, isSelected, description }) => {
  return (
    <ToggleButton
      type="radio"
      onClick={onClick}
      variant="outline-primary"
      checked={isSelected}
    >
      {description}
    </ToggleButton>
  );
};

const EasySubMenu = ({ subMenus, onMenuChanged, subMenuSelected }) => {
  return (
    <>
      <ButtonGroup className="d-flex flex-wrap py-3">
        {subMenus.map((subMenu) => (
          <EasySubMenuButton
            key={subMenu.id}
            onClick={() => onMenuChanged(subMenu)}
            isSelected={subMenuSelected === subMenu}
            description={subMenu.description}
          />
        ))}
      </ButtonGroup>
    </>
  );
};

export { EasySubMenuItem, EasySubMenu };
