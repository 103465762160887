import { useState } from "react";
import { Invoice } from "./useInvoiceService";
import api from "api";

interface Result {
    type: string;
}

interface SuccessResult extends Result {
    type: 'success';
}

interface ErrorResult extends Result {
    type: 'error';
    message: string;
}

interface LoadingResult extends Result {
    type: 'loading';
}

export type ServiceResult = SuccessResult | ErrorResult | LoadingResult;

export default function useInvoiceUpload() {
    const [uploadState, setUploadState] = useState<ServiceResult>();

    const handleFileSelected = (invoice: Invoice, file: File | null) => {
        if (file && file.type !== 'application/pdf') {
            setUploadState({ type: 'error', message: "Por favor, selecione um arquivo no formato PDF!" });
            return
        }

        setUploadState({ type: 'loading' });

        let formData = new FormData();
        file && formData.append("file", file)
        formData.append("idPayment", invoice.idPayment.toString())
        formData.append("seqRH", invoice.seqRH.toString())
        uploadForm(formData)
    };

    const uploadForm = async (formData: FormData) => {
        try {
            let { data } = await api.post("/user/invoice", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setUploadState({ type: 'success' });
        } catch (error) {
            setUploadState({ type: 'error', message: error.response?.data?.message ?? "Houve um erro no upload do arquivo, tente novamente!" });
        }
    }

    return {
        uploadState,
        handleFileSelected,
    };
}