import React, { useReducer } from "react";
import { Container } from "react-bootstrap";
import api from "api";
import EasyFeedbackModal from "components/EasyFeedbackModal";
import Loading from "components/Loading";
import EvidenceGrid from "./EvidenceGrid";
import { EasySubMenu, EasySubMenuItem } from "components/EasySubMenu";
import EvidenceDetail from "./EvidenceDetail";
import { SERVICES } from "utils/constants";
import { useState, useEffect } from "react";

class EvidenceSubMenuItem extends EasySubMenuItem {
  static ToApprove = new EvidenceSubMenuItem(
    "evidenceSubMenuToApprove",
    "Pendentes",
    SERVICES.MANAGEMENT_EVIDENCE_LIST("to-approve")
  );
  static Analyzed = new EvidenceSubMenuItem(
    "evidenceSubMenuToAnalyze",
    "Analisadas",
    SERVICES.MANAGEMENT_EVIDENCE_LIST("analyzed")
  );
  static Others = new EvidenceSubMenuItem(
    "evidenceSubMenuToOthers",
    "Outras",
    SERVICES.MANAGEMENT_EVIDENCE_LIST("informative")
  );

  static allSubMenus() {
    return Object.values(EvidenceSubMenuItem);
  }
}

const initialState = {
  reloadList: false,
  menuSelected: null,
  showLoading: false,
  feedbackModalParams: {
    show: false,
    title: "",
    description: "",
  },
  evidenceDetailParams: {
    show: false,
    evidence: null,
  },
  evidences: null,
};

const reducerActions = {
  feedback_modal_close_pressed: (state, _) => {
    return { ...state, feedbackModalParams: null };
  },
  getting_evidences: (state, _) => {
    return { ...state, showLoading: true, reloadList: false };
  },
  get_evidences_success: (state, action) => {
    return {
      ...state,
      showLoading: false,
      evidences: action.evidences,
      feedbackModalParams: null,
    };
  },
  get_evidences_error: (state, action) => {
    return {
      ...state,
      showLoading: false,
      evidences: [],
      feedbackModalParams: {
        show: true,
        title: "Houve um erro ao carregar a lista!",
        description: action.description ?? "Por favor, tente novamente...",
      },
    };
  },
  go_to_detail: (state, action) => {
    return {
      ...state,
      showLoading: false,
      evidenceDetailParams: {
        show: true,
        evidence: action.evidence,
      },
    };
  },
  detail_management_started: (state, _) => {
    return {
      ...state,
      showLoading: true,
      evidenceDetailParams: {
        show: false,
        evidence: null,
      },
    };
  },
  detail_close_pressed: (state, _) => {
    return {
      ...state,
      evidenceDetailParams: {
        show: false,
        evidence: null,
      },
    };
  },
  detail_management_error: (state, action) => {
    return {
      ...state,
      showLoading: false,
      feedbackModalParams: {
        show: true,
        title: "Houve um erro na análise!",
        description: action.description ?? "Por favor, tente novamente...",
      },
    };
  },
};

function reduce(state, action) {
  let reducerFunction = reducerActions[action.type];
  if (reducerFunction) {
    return reducerFunction(state, action);
  }
  console.log("[WARNING] Action withou a reducer:", action);
}

const LifeEvidence = () => {
  const [screenState, dispatch] = useReducer(reduce, initialState);
  const [menuSelected, setMenuSelected] = useState(EvidenceSubMenuItem.ToApprove);

  const getEvidences = async () => {
    try {
      dispatch({ type: "getting_evidences" });
      const response = await api.get(menuSelected.endpoint);
      dispatch({ type: "get_evidences_success", evidences: response.data });
    } catch (error) {
      dispatch({
        type: "get_evidences_error",
        description: error.response.data?.message,
      });
    }
  };

  useEffect(() => {
    getEvidences();
  }, [menuSelected]);

  return (
    <Container className="evidence-parent">
      {screenState.showLoading && (
        <Loading isLoading={screenState.showLoading} />
      )}
      <EasyFeedbackModal
        modalParams={screenState.feedbackModalParams}
        listener={() => dispatch({ type: "feedback_modal_close_pressed" })}
      />
      <div className="d-flex flex-column">
        <EasySubMenu
          subMenuSelected={menuSelected}
          subMenus={EvidenceSubMenuItem.allSubMenus()}
          onMenuChanged={setMenuSelected}
        />
        <EvidenceGrid
          evidences={screenState.evidences}
          onClickToManageItem={(item) =>
            dispatch({ type: "go_to_detail", evidence: item })
          }
        />
      </div>
      {screenState.evidenceDetailParams.show && (
        <EvidenceDetail
          show={screenState.evidenceDetailParams.show}
          evidence={screenState.evidenceDetailParams.evidence}
          onClose={() => dispatch({ type: "detail_close_pressed" })}
          onManagementSuccess={() => getEvidences()}
        />
      )}
    </Container>
  );
};

export default LifeEvidence;
