import InputMask from 'react-input-mask'

function TimeInput(props) {
  const mask = '12:34';
  const formatChars = {
    '1': '[0-2]',
    '2': '[0-9]',
    '3': '[0-5]',
    '4': '[0-9]'
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    const { value } = newState;
    
    if(value.startsWith('2'))
      formatChars['2'] = '[0-3]';
    else
      formatChars['2'] = '[0-9]';
    return {value, selection: newState.selection};
  }

  return (
      <InputMask 
        mask={mask}
        value={props.value} 
        onChange={props.onChange}
        onBlur={props.onBlur}
        formatChars={formatChars}
        beforeMaskedValueChange={beforeMaskedValueChange}
        className={props.className}
        id={props.id}
        placeholder={props.placeholder}
        required={props.required}
      />
    );
}

export default TimeInput