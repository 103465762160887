import React from "react";
import { Button } from "react-bootstrap";
import EasyInfoLabel from "components/EasyInfoLabel";
import EvidenceStatus from "./EvidenceStatus";
import DefaultImage from "images/default_image.svg";
import "./EvidenceItem.css";

const EvidenceItem = ({ item, onClickToManage }) => {
  const imageLoadError = (event) => {
    event.currentTarget.src = DefaultImage;
  };

  return (
    <div className="evidence-item">
      <img
        className="evidence-image"
        alt="Anexo da evidência"
        src={item.urlUpload}
        onError={imageLoadError}
      />
      <EvidenceStatus
        status={item.status}
        description={item.statusDescription}
      />
      <EasyInfoLabel title={"Colaborador:"} subTitle={item.collaborator} />
      {item.team && <EasyInfoLabel title={"Time:"} subTitle={item.team} />}
      <EasyInfoLabel title={"Escopo:"} subTitle={item.scope} />
      <EasyInfoLabel title={"Atividade:"} subTitle={item.activity} />
      <EasyInfoLabel title={"Data:"} subTitle={item.date} />
      {item.observation && (
        <EasyInfoLabel title={"Observação:"} subTitle={item.observation} />
      )}
      {item.needsApproval && (
        <Button variant="outline-primary" onClick={() => onClickToManage(item)}>
          Analisar
        </Button>
      )}
    </div>
  );
};

export default EvidenceItem;
