import styled from "styled-components";
import React from "react";

const EmptyStateContainer = styled.div`
  width: fit-content;
  margin: auto;
  background-color: #efefef;
  border-radius: 5px;
  gap: 20px;

  img {
    width: 100px;
  }

  p {
    text-align: center;
    color: #919191;
    font-weight: bold;
  }
`;

const EmptyState = (props) => {
  return (
    <EmptyStateContainer className={`d-flex flex-column align-items-center p-4 ` + props?.className ?? ''}>
      <img src={props.img} alt="Lista vazia" />
      <p>{props.message}</p>
    </EmptyStateContainer>
  );
};

export default EmptyState;
