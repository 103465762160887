import React, { useEffect, useState } from "react";
import { HEADER_CONTENT_TYPE_JSON, SERVICES } from "../../utils/constants";
import { Button, Form, Row, Col, Alert, Table, Container } from 'react-bootstrap';
import api from '../../api'
import TimeInput from "components/TimeInput";
import DateInput from "components/DateInput";

function Notification() {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);  
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [allUsers, setAllUsers] = useState(false)
  const [allClts, setAllClts] = useState(false)
  const [allHourlyPJs, setAllHourlyPJs] = useState(false)
  const [allMensalitsPJs, setAllMensalistsPJs] = useState(false)
  const [notificationDate, setNotificationDate] = useState("")
  const [hourScheduleDate, setHourScheduleDate] = useState("")
  const [emails, setEmails] = useState("");
  const [sendEmails, setSendEmails] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const handleDelete = async (id, e) => {
    e.preventDefault();
    setError('')
    setShowError(false)
    setShowSuccess(false)
    try {
      await api.delete(SERVICES.NOTIFICATION + "/" + id); 
      setNotifications(notifications.filter((v, i) => v.id !== id));
    } catch (error) {
      setError(error.response?.data?.message);
      setShowError(true)
      return;
    }
  }

  const findNotifications = async () => {
    try {
      const find = await api.get(SERVICES.NOTIFICATION, HEADER_CONTENT_TYPE_JSON); 
      setNotifications([])
      setNotifications(find?.data)
    } catch (error) {
      setError(error.response?.data?.message);
      setShowError(true)
      return;
    }
  }
  
  useEffect(() => {
    findNotifications()
  }, [])

  const handleSubmit = async(e) => {
    e.preventDefault();
    setError('')
    setShowError(false)
    setShowSuccess(false)
    setSendEmails([])
    setNotifications([])

    if (emails !== null && emails !== '') {
      emails.split(",").forEach(value => {
        sendEmails.push(value)
      })
    }

    try {
      const response = await api.post(SERVICES.NOTIFICATION,
        JSON.stringify(
          { 
            'all-users': allUsers, 
            'only-clts': allClts,
            'only-hourly-pjs': allHourlyPJs,
            'only-mensalist-pjs': allMensalitsPJs,
            'notificationDate': notificationDate,
            'scheduleDate': notificationDate + " " + hourScheduleDate,
            'title': title,
            'description': description,
            'emails': sendEmails
          }),
        HEADER_CONTENT_TYPE_JSON
      ); 

      for (const key in response.data) {        
        if (key === 'emails-fail') {
          setError(response?.data.message + " { " + response?.data[key] + " }")    
          setShowError(true)
          return
        }
      }  
      setSuccess(response?.data?.id)
      setShowSuccess(true)    
      findNotifications()
      return;
    } catch (error) {
      setShowError(true)
      setShowSuccess(false)
      setError(error.response?.data?.message);
      return;
    }
  };

  return (
    <Container className="p-4">
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <div key={`inline-radio`} className="mb-3">
            <Form.Check
              inline
              label="Todos os usuários"
              name="group1"
              type="radio"
              id={`inline-radio-1`}
              onClick={() => [setAllUsers(true), setAllClts(false), setAllMensalistsPJs(false), setAllHourlyPJs(false), setEmails([])]}
            />
            <Form.Check
              inline
              label="Todos os CLTs"
              name="group1"
              type="radio"
              id={`inline-radio-2`}
              onClick={() => [setAllUsers(false), setAllClts(true), setAllMensalistsPJs(false), setAllHourlyPJs(false), setEmails([])]}
            />
            <Form.Check
              inline
              name="group1"
              label="Todos os PJs Horistas"
              type="radio"
              id={`inline-radio-3`}
              onClick={() => [setAllUsers(false), setAllClts(false), setAllMensalistsPJs(false), setAllHourlyPJs(true), setEmails([])]}
            />
            <Form.Check
              inline
              name="group1"
              label="Todos os PJs Mensalistas"
              type="radio"
              id={`inline-radio-3`}
              onClick={() => [setAllUsers(false), setAllClts(false), setAllMensalistsPJs(true), setAllHourlyPJs(false), setEmails([])]}
            />
          </div>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="emails">
            <Form.Label>E-mails dos colaboradores:</Form.Label>
            <Form.Control type="text" placeholder="E-mails dos colaboradores"
              onChange={(e) => [setAllUsers(false), setAllClts(false), setAllMensalistsPJs(false), setAllHourlyPJs(false), setEmails(e.target.value)]} 
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="dateSchedule">
            <Form.Label>Data Agendamento:</Form.Label>
            <Form.Control required as={DateInput} mask="99/99/9999" placeholder="dd/MM/aaaa" onChange={(e) => [setNotificationDate(e.target.value)]} />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="timeSchedule">
            <Form.Label>Hora Agendamento:</Form.Label>
            <Form.Control as={TimeInput} mask="99:99" placeholder="HH:mm" required
              onChange={(e) => [setHourScheduleDate(e.target.value)]} />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="pushTitle">
            <Form.Label>Titulo:</Form.Label>
            <Form.Control type="text" placeholder="Titulo" required 
              onChange={(e) => [setTitle(e.target.value)]} />
            <Form.Control.Feedback type="invalid"> Titulo deve ser preenchido </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="pushMessage">
            <Form.Label>Mensagem:</Form.Label>
            <Form.Control as="textarea" placeholder="Digite sua mensagem aqui" style={{ height: '100px' }} required
              onChange={(e) => [setDescription(e.target.value)]} />
            <Form.Control.Feedback type="invalid"> Mensagem deve ser preenchida </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group>
              <Alert key='danger' variant='danger' show={showError}>
                {error}
              </Alert>
              <Alert show={showSuccess} variant="success">                
                <Alert.Heading>Agendamento com sucesso. Id: {success}</Alert.Heading>
              </Alert>
          </Form.Group>
          <Button variant="primary" type="submit">Agendar Notificação</Button>
        </Row>  
        <Table striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>Titulo</th>
              <th>Mensagem</th>
              <th>Data Notificação</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {notifications.map(value => (
              <tr key={value.id}>
                <td>{value.id}</td>
                <td>{value.title}</td>
                <td>{value.description}</td>
                <td>{value.notificationDate}</td>
                <td><Button variant="danger" type="button" onClick={e => handleDelete(value.id, e)}>Excluir</Button></td>
              </tr>
            ))}
          </tbody>
        </Table>    
      </Form>
    </Container>
  );
}

export default Notification