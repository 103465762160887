import { Button, Modal } from "react-bootstrap";

const EasyFeedbackModal = ({ show, title, description, listener }) => {
  return (
    show && (
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{description}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={listener}>
            Entendi
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default EasyFeedbackModal;
