import styled from "styled-components";

const ImageContainer = styled.img`
    width: 40px;
    height: 40px;
    margin-top: 5px;
`;

const TextContainer = styled.p`
    font-size: 20px;
    font-weight: 500;
    font-family: sans-serif;
    color: #777777;
    margin: 5px;
`;

const ItemContainer = styled.div`
    text-align: center;
    box-shadow: 1px 1px 3px #777777;
    border: .5px solid rgba(227,227,227,1);
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
`

const GridItem = ({ title, image, onItemClicked }) => {
    return (
            <ItemContainer onClick={onItemClicked}>
                <ImageContainer src={image} alt="Imagem do menu" />
                <TextContainer>{title}</TextContainer>
            </ItemContainer>
    );
}

export default GridItem;