import DateInput from "components/DateInput"
import React, { useState } from "react"
import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap"
import { session } from "utils"
import { HEADER_CONTENT_TYPE_JSON, PERMISSIONS, SERVICES } from "utils/constants"
import api from "../../api"

function CollaboratorHour() {
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [collaborator, setCollaborator] = useState("")
  const [init, setInit] = useState("")
  const [end, setEnd] = useState("")
  const [hours, setHours] = useState([])
  const [showEmpty, setShowEmpty] = useState(false)

  const initialized = () => {
    setError("")
    setSuccess("")
    setShowError(false)
    setShowSuccess(false)
    setShowEmpty(false)
  }

  const findByCollaborator = async(e) => {
    e.preventDefault()
    initialized()

    if (collaborator === '' || collaborator.replace(/ /g, '') === '') {
      setError("Usuário do colaborador deve ser preenchido");
      setShowError(true)
      return;
    }

    try {
      let queryParam = ""
      if (init !== '' || end !== '') {
        queryParam = "?init=" + init?.replace(/\//g, "") + "&end=" + end?.replace(/\//g, "")
      }
      await api.get(SERVICES.V2_HOUR_ADMIN + "/" + collaborator + queryParam, HEADER_CONTENT_TYPE_JSON)
      .then(res => {
        setHours([])
        setHours(res?.data)
        console.log("RESPOSTA:", res?.data)
      })
      setShowEmpty(true)
    } catch (error) {
      if (error?.code === 'ERR_NETWORK') {
        setError("Erro ao comunicar com o servidor.");
        setShowError(true)
        return;  
      }
      setError(error.response?.data?.message);
      setShowError(true)
      return;
    }
  }

  const deleteHour = async(id, e) => {
    e.preventDefault()
    initialized()

    try {
      await api.delete(SERVICES.V2_HOUR_ADMIN + "/" + id, HEADER_CONTENT_TYPE_JSON)
      .then(res => {
        setHours(hours.filter((v, i) => v.id !== id));
      })
      setShowEmpty(true)
    } catch (error) {
      if (error?.code === 'ERR_NETWORK') {
        setError("Erro ao comunicar com o servidor.");
        setShowError(true)
        return;  
      }
      setError(error.response?.data?.message);
      setShowError(true)
      return;
    }
  }

  return (
    <>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="collaboratorFind">
          <Form.Label> Usuário do Colaborador: </Form.Label>
          <Form.Control type="text" placeholder="Usuário do colaborador" onChange={(e) => [setCollaborator(e.target.value)]} />
        </Form.Group>
        <Form.Group as={Col} controlId="collaboratorFind">
          <Form.Label> Inicio: </Form.Label>
          <Form.Control required as={DateInput} mask="99/99/9999" placeholder="dd/MM/aaaa" onChange={(e) => [setInit(e.target.value)]} />
        </Form.Group>
        <Form.Group as={Col} controlId="collaboratorFind">
          <Form.Label> Fim: </Form.Label>
          <Form.Control required as={DateInput} mask="99/99/9999" placeholder="dd/MM/aaaa" onChange={(e) => [setEnd(e.target.value)]} />
        </Form.Group>
      </Row>
      <Form.Group as={Row} className="mb-3" controlId="collaboratorFindButton">              
        <Button variant="success" type="button" onClick={e => [setShowError(false), setShowSuccess(false), setError(''), setSuccess(''), findByCollaborator(e)]}> Buscar Lançamentos </Button>
      </Form.Group>                
      <Form.Group as={Row} className="mb-3" controlId="colHourAlert">
        <Col sm="12">
          <Alert key='danger' variant='danger' show={showError}>
            {error}
          </Alert>
          <Alert show={showSuccess} variant="success">                
            <Alert.Heading>{success}</Alert.Heading>
          </Alert>
        </Col>
      </Form.Group>        
      {
        hours?.length > 0 ? 
        (
          <Table striped> 
            <thead>
              <tr>
                <th>Tipe Lançamento</th>
                <th>Inicio</th>
                <th>Dia da Semana</th>
                <th>Fim</th>
                <th>Dia da Semana</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {hours.map(value => (
                <tr key={value.id}>
                  <td>{value.type === 'ABSENCE' ? 'Ausência' : 'Extra'}</td>
                  <td>{value.init}</td>
                  <td>{value.dayOfWeekInit}</td>
                  <td>{value.end}</td>
                  <td>{value.dayOfWeekEnd}</td>
                  <td>
                    {session.permissions().includes(PERMISSIONS.hour_exception_delete) ? (
                      <Button variant="danger" type="button" onClick={e => [setShowError(false), setShowSuccess(false), setError(''), setSuccess(''), deleteHour(value.id, e)]}> Excluir </Button>
                    ) : (<></>) 
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>       
        ) 
        : (                
          <Alert show={showEmpty} variant="success">                
            <Alert.Heading>Não há lançamentos para serem exibidos</Alert.Heading>
          </Alert>
        )
      }      
    </>
  )
}

export default CollaboratorHour