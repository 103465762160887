import api from "api";
import { SERVICES, URL_PAGES } from "utils/constants";
import EmptyState from "components/EmptyState";
import EmptyStateImage from "images/empty_box.svg";
import { useState, useReducer } from "react";
import Loading from "components/Loading";
import { Alert, Button, Container, Row, Table, InputGroup, Form, Col } from "react-bootstrap";
import { useEffect } from "react";
import { PageSelector } from "components/PageSelector";
import { useSearchParams, Link } from "react-router-dom";

const initialState = {
    showEmptyState: true,
    loadingItems: false,
    errorToLoadTeams: false,
    feedbackMessage: ""
};

const reducerActions = {
    loading_team_page: (state, _) => {
        return { ...state, loadingItems: true, errorToLoadTeams: false }
    },
    empty_teams_list: (state, _) => {
        return { ...state, loadingItems: false, showEmptyState: true, errorToLoadTeams: false }
    },
    teams_list_loaded_successfully: (state, _) => {
        return { ...state, loadingItems: false, showEmptyState: false, errorToLoadTeams: false }
    },
    failure_to_load_teams: (state, action) => {
        return { ...state, loadingItems: false, showEmptyState: false, errorToLoadTeams: true, feedbackMessage: action.message }
    }

}

function reduce(state, action) {
    let reducerFunction = reducerActions[action.type];
    if (reducerFunction) {
        return reducerFunction(state, action);
    }
    console.log("[WARNING] Action without a reducer:", action);
}

const EscalationHome = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(searchParams.get("page") ?? 1);
    const [teamsPage, setTeamsPage] = useState({});
    const [screenState, dispatch] = useReducer(reduce, initialState);
    const [searchText, setSearchText] = useState({
        text: "",
        updateList: false
    });

    useEffect(() => {
        getTeams()
    }, [currentPage]);

    useEffect(() => {
        if (searchText.updateList === true) {
            setSearchText({ ...searchText, updateList: false })
            getTheFirstPage();
        }
    }, [searchText])

    const getTheFirstPage = () => {
        const oldCurrentPage = currentPage
        setCurrentPage(1);
        searchParams.delete("page");
        setSearchParams(searchParams);
        setTeamsPage({});
        if (oldCurrentPage === 1) {
            getTeams()
        }
    }

    const onChangePage = (newPage) => {
        setCurrentPage(newPage)
        setSearchParams({ page: newPage })
    }

    const onSearchTeam = (event) => {
        event.preventDefault();
        event.stopPropagation();
        getTheFirstPage();
    }

    const onResetSearch = () => {
        setSearchText({
            text: "",
            updateList: true
        });
    }

    const getParams = () => {
        let params = {
            page: currentPage - 1,
            'qtd-lines': 15
        }

        if (searchText.text !== "") {
            params = {
                ...params,
                'team-name': searchText
            }
        }

        const urlSearchParams = new URLSearchParams(params)
        return urlSearchParams.toString()
    }

    const getTeams = async () => {
        try {
            dispatch({ type: "loading_team_page" })
            const response = await api.get(SERVICES.TEAM_ADMIN + `?${getParams()}`)
            const page = {
                items: response.data.teams,
                totalPages: response.data["last-page"]
            }
            setTeamsPage(page)
            let teamItems = page.items ?? []
            if (teamItems.length > 0) {
                dispatch({ type: "teams_list_loaded_successfully" })
            } else {
                dispatch({ type: "empty_teams_list" })
            }
        } catch (error) {
            dispatch({ type: "failure_to_load_teams", message: error.response.data.message ?? "Ops, houve um erro! Tente novamente..." })
        }
    };

    let contentToShow
    if (screenState.errorToLoadTeams) {
        contentToShow = <p>{screenState.feedbackMessage}</p>
    } else if (screenState.showEmptyState) {
        contentToShow = (
            <EmptyState
                message={"Não há times registrados..."}
                img={EmptyStateImage}
            />
        );
    } else {
        contentToShow = (
            <Row>
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teamsPage.items?.map((item, index) =>
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td><div>{item.name}</div></td>
                                <td>
                                    <div className="d-flex justify-content-end">
                                        <Link to={URL_PAGES.ESCALATION_LIST} state={{ team: item }}>
                                            <Button variant="outline-primary">Visualizar</Button>
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <PageSelector
                    onSelectPage={onChangePage}
                    pageSelected={currentPage}
                    numberOfPages={teamsPage.totalPages} />
            </Row>
        );
    }

    return (
        <Container>
            <Loading isLoading={screenState.loadingItems} />
            <Alert variant="info">
                <Alert.Heading>Escalation dos times</Alert.Heading>
                <p>
                    Abaixo são mostrados somente os times com colaboradores cadastrados.<br />
                    Selecione um time para visualizar a escalation
                </p>
            </Alert>
            <Row>
                <Col md={6}>
                    <Form onSubmit={onSearchTeam}>
                        <InputGroup className="mb-4">
                            <Form.Control
                                placeholder="Pesquisar time"
                                aria-label="Pesquisar time"
                                value={searchText.text}
                                onChange={(event) => setSearchText(event.target.value)} />
                            <Button variant="outline-secondary" onClick={onResetSearch}>
                                Limpar
                            </Button>
                            <Button variant="secondary" type="submit">
                                Pesquisar
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>
            {contentToShow}
        </Container>
    );
}

export default EscalationHome;