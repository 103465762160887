import api from "api";
import { SERVICES } from "utils/constants";

export class Teams {
    name: string;
    description: string;
    id: number;
}

export class Collaborator {
    id: number;
    name: string;
    username: string;
    role?: string;
    imageUrl?: string;
    occupation?: string;
    teams?: Teams[];

    constructor(
        id: number,
        name: string,
        login: string,
        role?: string,
        imageUrl?: string,
        occupation?: string,
        teams?: Teams[]
    ) {
        this.id = id;
        this.name = name;
        this.username = login;
        this.role = role;
        this.imageUrl = imageUrl;
        this.occupation = occupation;
        this.teams = teams;
    }
}

class CollaboratorResponse {
    id: number;
    name: string;
    login: string;
    image?: string;
    jobRole?: string;
    occupation?: string;
    teams?: Teams[];

    constructor(
        id: number,
        name: string,
        login: string,
        imageUrl?: string,
        jobRole?: string,
        occupation?: string,
        teams?: Teams[]

    ) {
        this.id = id;
        this.name = name;
        this.login = login;
        this.image = imageUrl;
        this.jobRole = jobRole;
        this.occupation = occupation;
        this.teams = teams;
    }

    mapToCollaborator(): Collaborator {
        return new Collaborator(
            this.id,
            this.name,
            this.login,
            this.image,
            this.jobRole,
            this.occupation,
            this.teams
        )
    }
}

export interface CollaboratorPage {
    lastPage: number,
    collaborators: Collaborator[]
}

export class CollaboratorsService {

    async getCollaborators(
        currentPage: number,
        numberOfLines: number,
        searchValue?: string,
        userWithoutTeam?: boolean,
    ):
        Promise<CollaboratorPage> {

        const getParams = () => {
            let params = {
                page: (currentPage - 1).toString(),
                'qtd-lines': numberOfLines.toString(),
                'name': searchValue ?? "",
                'without_team': String(userWithoutTeam).toString()
            }

            const urlSearchParams = new URLSearchParams(params);
            return urlSearchParams.toString()
        };

        const { data } = await api.get(`/user/admin/collaborators?${getParams()}`)
        const lastPage = data.lastPage

        const collaborators = data.collaborators.map((collaborator: CollaboratorResponse) => new Collaborator(
            collaborator.id,
            collaborator.name,
            collaborator.login,
            collaborator.jobRole,
            collaborator.image,
            collaborator.occupation,
            collaborator.teams
        ))

        return { 
            lastPage: lastPage,
            collaborators: collaborators
        }
    }
}