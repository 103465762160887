import React, { ReactNode } from "react";
import { Form, Button, Row } from "react-bootstrap";
import styled from "styled-components";
import DefaultImage from "images/default_image.svg";
import DownloadIcon from "images/download_icon.svg";
import DeleteIcon from "images/delete_icon.svg";

const TeamImage = styled.img`
    width: fit-content;
    height: 300px;
`

export interface TeamFormParams {
    name: string,
    description?: string,
    image?: string,
    os?: string
}

interface TeamFormProps {
    formValid: boolean,
    onSubmitForm: (event: React.BaseSyntheticEvent) => void,
    onChangeField: (element: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onSelectImage: (element: any) => void,
    onlyReadName: boolean | undefined,
    teamParams?: TeamFormParams,
    children?: ReactNode
};


function UploadButton(props: { onChange: (element: any) => void, className?: string }) {
    return (
        <Button variant="secondary" className={props?.className}>
            <label onChange={props.onChange} htmlFor="imageSelected" style={{ cursor: "pointer" }} >
                <img src={DownloadIcon} alt="asdasd" style={{ width: "30px" }} className="m-1" />
                <div>Selecionar imagem</div>
                <input name="" type="file" id="imageSelected" hidden />
            </label>
        </Button>
    );
}

export function HandleTeamForm(props: TeamFormProps) {
    let teamParams = props?.teamParams
    return (
        <Form
            id="team-form"
            className="row g-1"
            noValidate
            validated={props.formValid}
            onSubmit={props.onSubmitForm}>

            <Row className="d-flex justify-content-center g-2">
                <TeamImage className="row" src={teamParams?.image ?? DefaultImage} alt="Imagem do time" />
                <Row className="d-flex justify-content-center g-2">
                    <div className="col-lg-2">
                        <Row className="d-flex justify-content-center">
                            <UploadButton className="col-sm-10" onChange={props.onSelectImage} />
                        </Row>
                    </div>
                    <div className="col-lg-2">
                        <Row className="d-flex justify-content-center">
                            <Button
                                className="col-sm-10" onClick={() => props.onSelectImage(undefined)}
                                variant="outline-danger">
                                <img src={DeleteIcon} alt="asdasd" style={{ width: "25px" }} className="m-1" />
                                <div>
                                    Remover imagem
                                </div>
                            </Button>
                        </Row>
                    </div>
                </Row>
            </Row>

            <Row className=" d-flex justify-content-center g-2">
                <Form.Group className="col-12">
                    <Form.Label>Nome do time:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Smallville"
                        name="name"
                        value={teamParams?.name}
                        readOnly={props.onlyReadName}
                        onChange={props.onChangeField}
                        required />
                    <Form.Control.Feedback type="invalid">
                        Nome do time é obrigatório
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-12" >
                    <Form.Label>Descrição:</Form.Label>
                    <Form.Control
                        placeholder="Diga um pouco mais sobre a história de criação do time ou seu objetivo."
                        name="description"
                        value={teamParams?.description}
                        onChange={props.onChangeField}
                        as="textarea" rows={3} />
                </Form.Group>
                <Form.Group className="col-12" >
                    <Form.Label>OS:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite aqui a OS do time em questão"
                        name="os"
                        value={teamParams?.os}
                        onChange={props.onChangeField} />
                </Form.Group>
                {props?.children}
            </Row>
        </Form>
    );
};