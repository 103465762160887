import React from "react";
import "./style.css";

const EvidenceInfoLabel = ({ title, subTitle }) => {
  return (
    <div className="evidence-info-label">
      <h4>{title}</h4>
      <p>{subTitle}</p>
    </div>
  );
};

export default EvidenceInfoLabel;
