import { Modal } from "react-bootstrap";
import CollaboratorSelectedForm from "./CollaboratorSelectedForm";
import { Collaborator } from "pages/Team/TeamService";

interface DeleteCollaboratorModalProps {
    collaborator: Collaborator | undefined,
    show: boolean,
    onCloseModal: () => void,
    onSubmitValidForm: (collaborator: Collaborator, date: string) => void
}

export default function DeleteCollaboratorModal(props: DeleteCollaboratorModalProps) {
    return props?.collaborator && <div>
        <Modal size="xl" show={props.show} onHide={props.onCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Preencha os dados para remover o colaborador</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CollaboratorSelectedForm
                    dateLabel="Data de saída:"
                    buttonVariant="danger"
                    buttonLabel="Remover"
                    collaborator={props.collaborator}
                    onCollaboratorSelected={props.onSubmitValidForm}
                    onCancel={props.onCloseModal}
                />
            </Modal.Body>
        </Modal>
    </div>
}