import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Collaborator } from "../List/CollaboratorsService";
import { useState } from "react";
import PersonImage from "images/person_image.svg";
import { ContactCard, ContactImage, ContactImageLarge, RoleContainer } from "../components/CollaboratorItem";

export class ValidationCollaborator {
    static validation(text: string): string {
        if (text === "" || text === null) {
            return "N/A"
        }
        return text
    }
}

interface CollaboratorDetailProps {
    collaborator: Collaborator,
    isButtonVisible: boolean,
    onClickToRemoveCollaborator: () => void,
    onClickToAddCollaborator: () => void
}

export default function CollaboratorDetail(props: CollaboratorDetailProps) {
    return (
        <Row>
            <ContactCard>
                <Col md={4} lg={4}>
                    <ContactImageLarge
                        roundedCircle
                        src={props.collaborator.imageUrl ?? PersonImage}
                        alt="imagem do contato"
                    />
                </Col>
                <Col sm={10} lg={6}>
                    <div className="jumbotron">
                        <h1 className="display-4">{props.collaborator.name}</h1>
                        <p className="lead">Usuário: {props.collaborator.username}</p>
                        <p className="lead">Email: {props.collaborator.username}@tqi.com.br</p>
                        <p className="lead">Ocupação: {ValidationCollaborator.validation(props.collaborator.occupation ?? "")}</p>
                        <p className="lead">Time: {props.collaborator?.teams?.[0]?.name ?? "N/A"}</p>

                        <Row>
                            <Col>
                                <Button
                                    className="me-2"
                                    size="sm"
                                    variant="danger"
                                    onClick={props.onClickToRemoveCollaborator}
                                    disabled={!props.isButtonVisible}

                                >
                                    Remover do Time
                                </Button>
                                <Button
                                    size="sm"
                                    variant="primary"
                                    onClick={props.onClickToAddCollaborator}
                                    disabled={props.isButtonVisible}
                                >
                                    Adicionar em um time
                                </Button>

                            </Col>
                        </Row>
                    </div>
                </Col>
            </ContactCard>
        </Row>
    );
};
