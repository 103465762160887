
import React from "react";
import DefaultImage from "images/default_image.svg";
import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";

const ItemContainer = styled(Row)`
    text-align: center;
    color: #8f8f8f;
    border: solid 1px #e6e6e6;
    border-radius: 5px;
    padding: 8px;
    box-shadow: 5px 5px 8px 2px rgba(233, 233, 233, 0.8);
`;

const TeamImage = styled.img`
    height: 120px;
    width: fit-content;
    display: block;
    margin: auto;
`;

interface TeamData {
    id: number,
    name: string,
    image?: string,
}

interface TeamProps {
    team: TeamData,
    onClick: (team: TeamData) => void,
    buttonLabel: string
}

export default function TeamListItem(props: TeamProps) {
    let team = props.team
    let altDescription = 'Imagem do time ' + team.name
    const imageLoadError = (event: any) => {
        event.currentTarget.src = DefaultImage;
    };
    return <Col sm={4} className="g-3">
        <ItemContainer className="g-1">
            <TeamImage
                alt={altDescription}
                src={team?.image ?? DefaultImage}
                onError={imageLoadError}
            />
            <h5>{team.name}</h5>
            <Row className="g-2">
                <Button variant="outline-primary" onClick={() => props.onClick(team)}>{props.buttonLabel}</Button>
            </Row>
        </ItemContainer>
    </Col>;
}