import Events from "pages/Event";
import HourException from "pages/HourException";
import OnCall from "pages/OnCall";
import Signin from "pages/Signin";
import { EditTeam, TeamList, CreateTeam } from "pages/Team";
import { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { session } from "utils";
import { PERMISSIONS, URL_PAGES } from "utils/constants";
import Home from "../pages/Home";
import Notification from "../pages/Notification";
import LifeEvidence from "../pages/LifeEvidence";
import { CollaboratorsDetail, CollaboratorsList } from "pages/Collaborators";
import { EscalationHome, EscalationList, CreateEscalation } from "../pages/Escalation";
import CollaboratorInvoice from "pages/Invoice";

const Private = ({ Item, Permission }) => {
  const token = session.authToken();
  const sessionPermissions = session.permissions();
  let permissions = [];
  if (sessionPermissions.length > 0) {
    permissions = sessionPermissions.split(",");
  }
  const hasToken = token && token !== "";
  const checkPermission = permissions.find((e) => e === Permission);
  return hasToken && checkPermission === Permission ? (
    <Item />
  ) : (
    <Home hasPermission={checkPermission} showLogin={!hasToken} />
  );
};

const HomePermission = () => {
  const token = session.authToken();
  if (token && token !== "") {
    return <Home hasPermission={true} showLogin={false} />;
  }
  return <Home hasPermission={false} showLogin={true} />;
};

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route
            exact
            path={URL_PAGES.NOTIFICATIONS}
            element={
              <Private
                Item={Notification}
                Permission={PERMISSIONS.notifications}
              />
            }
          />
          <Route
            exact
            path={URL_PAGES.ONCALL}
            element={
              <Private
                Item={OnCall}
                Permission={PERMISSIONS.hour_oncall_admin}
              />
            }
          />
          <Route
            exact
            path={URL_PAGES.HOUR_EXCEPTION}
            element={
              <Private
                Item={HourException}
                Permission={PERMISSIONS.hour_exception_throw_admin}
              />
            }
          />
          <Route
            exact
            path={URL_PAGES.EVENTS}
            element={<Private Item={Events} Permission={PERMISSIONS.events_adm} />}
          />
          <Route
            exact
            path={URL_PAGES.TEAMS}
            element={<Private Item={TeamList} Permission={PERMISSIONS.teams_adm} />}
          />
          <Route
            exact
            path={URL_PAGES.TEAM_CREATION}
            element={<Private Item={CreateTeam} Permission={PERMISSIONS.teams_adm} />}
          />
          <Route
            exact
            path={URL_PAGES.TEAM_EDITION}
            element={<Private Item={EditTeam} Permission={PERMISSIONS.teams_adm} />}
          />
          <Route
            exact
            path={URL_PAGES.EVIDENCE}
            element={<Private Item={LifeEvidence} Permission={PERMISSIONS.evidence_approver} />}
          />
          <Route
            exact
            path={URL_PAGES.ESCALATION_HOME}
            element={<Private Item={EscalationHome} Permission={PERMISSIONS.escalation_management} />}
          />
          <Route
            exact
            path={URL_PAGES.ESCALATION_LIST}
            element={<Private Item={EscalationList} Permission={PERMISSIONS.escalation_management} />}
          />
          <Route
            exact
            path={URL_PAGES.ESCALATION_CREATION}
            element={<Private Item={CreateEscalation} Permission={PERMISSIONS.escalation_management} />}
          />
          <Route
            exact
            path={URL_PAGES.COLLABORATORS}
            element={<Private Item={CollaboratorsList} Permission={PERMISSIONS.teams_adm} />}
          />
          <Route
            exact
            path={URL_PAGES.COLLABORATORSDETAIL}
            element={<Private Item={CollaboratorsDetail} Permission={PERMISSIONS.teams_adm} />}
          />
          <Route
            exact
            path={URL_PAGES.INVOICE}
            element={<Private Item={CollaboratorInvoice}
              Permission={PERMISSIONS.collaborator_invoice} />}
          />
          <Route exact path="/" element={<HomePermission />} />
          <Route exact path="/login" element={<Signin />} />
          <Route path="*" element={<HomePermission />} />
        </Routes>
      </Fragment>
    </BrowserRouter>
  );
};

export default RoutesApp;
