import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

export default function EditionFormButtons(props: { onClickToEditEscalation: () => void, onClickToDelete: () => void }) {
    return (
        <Container>
            <Row className="d-flex justify-content-center mt-4 g-2">
                <Col lg={3}>
                    <Button
                        size="lg"
                        variant="primary"
                        type="submit"
                        style={{ width: "100%", height: "100%" }}>
                        Salvar
                    </Button>
                </Col>
                <Col lg={3}>
                    <Button
                        size="lg"
                        variant="outline-primary"
                        style={{ width: "100%", height: "100%" }}
                        onClick={props.onClickToEditEscalation}>
                        Editar escalation
                    </Button>
                </Col>
                <Col lg={3}>
                    <Button
                        size="lg"
                        variant="danger"
                        style={{ width: "100%", height: "100%" }}
                        onClick={props.onClickToDelete}>
                        Deletar time
                    </Button>
                </Col>
            </Row>
        </Container>

    );
}