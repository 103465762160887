import EmptyState from "components/EmptyState";
import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import EmptyStateImage from "images/empty_box.svg";
import CollaboratorItem from "./CollaboratorItem";

interface Collaborator {
    id: number,
    username: string,
    name: string,
    role?: string,
    imageUrl?: string
}

interface CollaboratorListProps {
    collaborators?: Collaborator[],
    onClickToAddCollaborator: () => void,
    onClickToRemoveCollaborator: (collaborator: Collaborator) => void
}

export default function CollaboratorList(props: CollaboratorListProps) {
    return (
        <Container>
            <Row className="g-2">
                <h3 className="col"> Lista de colaboradores </h3>
                <Col lg={2}>
                    <Button variant="secondary" size="sm" style={{ width: "100%" }} onClick={props.onClickToAddCollaborator}>
                        Adicionar novo colaborador
                    </Button>
                </Col>
            </Row>
            <div>
                {props?.collaborators ?
                    <Row className="gy-1 mt-1">
                        {props?.collaborators?.map((item, index) =>
                            <CollaboratorItem
                                onClickToRemoveCollaborator={props.onClickToRemoveCollaborator}
                                key={index}
                                buttonLabel="Remover"
                                item={item} />
                        )}
                    </Row> : <EmptyState
                        className="mt-3"
                        message={"Nenhum colaborador encontrado..."}
                        img={EmptyStateImage}
                    />
                }
            </div>
        </Container>
    );
}