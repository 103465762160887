import { AxiosError } from "axios";
import TeamListService from "pages/Team/List/TeamListService";
import { TeamItem } from "pages/Team/List/components/TeamList";
import { Team, TeamPage } from "pages/Team/TeamService";
import { SearchComponent } from "pages/Team/components/SearchComponent";
import { useEffect, useState } from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";
import { CollaboratorsnFeedback } from "../components/CollaboratorsnFeedback";
import { useSearchParams } from "react-router-dom";
import TeamsToSelectList from "../components/TeamsToSelectList";

interface SelectTeamModalProps {
    show: boolean,
    onCloseModal: () => void
    onItemClicked?: (team: TeamItem) => void,

}

export default function SelectTeamModal(props: SelectTeamModalProps) {

    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, showLoading] = useState(false);
    const [errorFeedback, setErrorFeedback] = useState<string>();
    const [searchValue, setSearchValue] = useState<string>()
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page") ?? 1));
    const [teamsList, setTeamsList] = useState<TeamItem[]>()
    const [emptyState, showEmptyState] = useState(false)

    const getTeams = () => {
        const service = new TeamListService();
        showLoading(true)

        service.getTeams(currentPage, 22, searchValue)
            .then((page: TeamPage) => {
                const teams = page.teams.map((team: Team) => {
                    return {
                        id: team.id,
                        name: team.name,
                        description: team.description,
                        image: team.imageUrl,
                        os: team.os
                    }
                })

                setTeamsList(teams)
                let teamItems = teamsList ?? []
                if (teamItems.length > 0) {
                    showEmptyState(false)
                } else {
                    showEmptyState(true)
                }
            })
            .catch((error: AxiosError | any) => {
                const errorMessage = error?.response.data?.message

                CollaboratorsnFeedback.createFeedback(
                    "Houve um erro",
                    errorMessage ?? "Tivemos um erro ao buscar as informações, por favor tente novamente."
                )
            })
            .finally(() => showLoading(false))
    };

    useEffect(() => {
        getTeams()
    }, [searchValue]);

    return (
        <div>
            <Modal size="xl" show={props.show} onHide={props.onCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Selecione um time abaixo</Modal.Title>
                </Modal.Header>

                <SearchComponent
                    placeholder="Digite o nome do time"
                    className="col-md-6"
                    value={searchValue}
                    onSubmitSearch={() => getTeams()}
                    onSearchValueChanged={setSearchValue}
                />
                
                <div key={`inline-radio`} className="mb-3"></div>

                <TeamsToSelectList
                    teams={teamsList}
                    onItemClicked= { (item: TeamItem) => { props?.onItemClicked && props?.onItemClicked(item) }}
                />

            </Modal >
        </div >
    );

}