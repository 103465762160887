import React, { useEffect, useState } from "react";
import { TeamHeaderTitle } from "../components/TeamHeaderTitle";
import { HandleTeamForm } from "../components/HandleTeamForm";
import Loading from "components/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import EasyFeedbackModal from "components/EasyFeedbackModal";
import TeamContainer from "../components/TeamContainer";
import { Collaborator, Team } from "../TeamService";
import { AxiosError } from "axios";
import EditionFormButtons from "./components/EditionFormButtons";
import { URL_PAGES } from "utils/constants";
import SeparatorLine from "../components/SeparatorLine";
import CollaboratorList from "./components/CollaboratorList";
import { SelectCollaboratorModal } from "./components/SelectCollaboratorModal";
import EditTeamService from "./EditTeamService";
import BackButton from "../components/BackButton";
import DeleteCollaboratorModal from "./components/DeleteCollaboratorModal";

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>

class TeamEditionFeedback {
    title: string;
    message: string;
    private onClick: () => void;
    show: boolean;

    constructor(title: string, message: string, onClick: () => void) {
        this.title = title
        this.message = message
        this.onClick = onClick
        this.show = true
    }

    clickToConfirm() {
        this?.onClick && this.onClick()
    }

    static createFeedback(
        title: string,
        message: string,
        onClick = () => { }
    ) {
        return new TeamEditionFeedback(title, message, onClick)
    }
}

interface FormParams {
    name: string;
    description?: string;
    image?: string;
    imageSelected?: File;
    os?: string;
}

interface DeleteCollaboratorModal {
    show: boolean,
    collaboratorSelected: Collaborator
}

export default function EditTeam() {
    const navigate = useNavigate()
    const location = useLocation()
    const { team } = location.state

    const initialFormParams = {
        name: team.name,
        description: team.description,
        image: team.image,
        imageSelected: undefined,
        os: team.os
    }

    const [formParams, setFormParams] = useState<FormParams>(initialFormParams);
    const [isFormValid, setFormValid] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<TeamEditionFeedback>();
    const [collaborators, setCollaborators] = useState<Collaborator[]>();
    const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
    const [deleteCollaboratorModal, setDeleteCollaboratorModal] = useState<DeleteCollaboratorModal>();

    const handleChange = (event: ChangeEvent) => {
        let { name, value } = event.target
        setFormParams({ ...formParams, [name]: value })
    }

    const handleImageChanged = (event: any) => {
        let files = event?.target?.files
        let file: File | undefined = undefined
        if (files && files[0]) {
            file = files[0]
        }

        let imageUrl = file && URL.createObjectURL(file);
        setFormParams({ ...formParams, image: imageUrl, imageSelected: file });
    }

    const onSubmitForm = (event: React.BaseSyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            updateTeam()
        }
        setFormValid(true);
    };

    const updateTeam = () => {
        const teamService = new EditTeamService()
        setLoading(true)
        teamService.editTeam(
            team.id,
            formParams.name,
            formParams.description,
            formParams.image,
            formParams.os,
            formParams.imageSelected
        ).then((team: Team) => {
            console.log(team);
            setFeedback(
                TeamEditionFeedback.createFeedback(
                    "Alterações salvas com sucesso",
                    "Suas alterações foram salvas com sucesso."
                )
            )
        }).catch((error: AxiosError | any) => {
            const errorMessage = error?.response.data?.message
            setFeedback(
                TeamEditionFeedback.createFeedback(
                    "Houve um erro ao salvar o time",
                    errorMessage ?? "Tivemos um problema ao salvar as alterações. Por favor, tente novamente."
                )
            )
        }).finally(() => setLoading(false))
    };

    const onClickToDeleteTeam = () => {
        const teamService = new EditTeamService()
        setLoading(true)
        teamService.deleteTeam(team.id)
            .then((response: any) => {
                setFeedback(
                    TeamEditionFeedback.createFeedback(
                        "Time deletado com sucesso",
                        "O time foi deletado com sucesso",
                        () => navigate(-1)
                    )
                )
            })
            .catch((error: AxiosError | any) => {
                const errorMessage = error?.response.data?.message
                setFeedback(
                    TeamEditionFeedback.createFeedback(
                        "Houve um erro ao salvar o time",
                        errorMessage ?? "Tivemos um problema ao salvar as alterações. Por favor, tente novamente."
                    )
                )
            })
            .finally(() => setLoading(false))
    };

    const getCollaboratorList = () => {
        const teamService = new EditTeamService()
        setLoading(true)
        teamService.getCollaboratorsByTeam(team.name)
            .then((response: Collaborator[]) => setCollaborators(response))
            .catch((error: AxiosError | any) => {
                const errorMessage = error?.response?.data?.message
                setFeedback(
                    TeamEditionFeedback.createFeedback(
                        "Erro ao buscar colaboradores",
                        errorMessage ?? "Tivemos um problema ao buscar a lista de colaboradores. Por favor, tente novamente."
                    )
                )
            })
            .finally(() => setLoading(false))
    };

    const addCollaboratorToTeam = (collaborator: Collaborator, date: string) => {
        const service = new EditTeamService()
        setLoading(true)
        service.addUserToTeam(team.id, collaborator.username, date)
            .then((response: any) => {
                getCollaboratorList();
            })
            .catch((error: AxiosError | any) => {
                const errorMessage = error?.response?.data?.message
                setFeedback(
                    TeamEditionFeedback.createFeedback(
                        "Erro ao adicionar colaborador",
                        errorMessage ?? "Tivemos um problema ao adicionar o colaborador. Por favor, tente novamente."
                    )
                )
            })
            .finally(() => setLoading(false))
    }

    const removeCollaboratorToTeam = (collaborator: Collaborator, date: string) => {
        const service = new EditTeamService()
        setLoading(true)
        setDeleteCollaboratorModal(undefined)
        service.removeUserToTeam(team.id, collaborator.id, date)
            .then((response: any) => {
                getCollaboratorList();
            })
            .catch((error: AxiosError | any) => {
                const errorMessage = error?.response?.data?.message
                setFeedback(
                    TeamEditionFeedback.createFeedback(
                        "Erro ao remover colaborador",
                        errorMessage ?? "Tivemos um problema ao remover o colaborador. Por favor, tente novamente."
                    )
                )
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getCollaboratorList()
    }, [])

    return (
        <TeamContainer>
            <Loading isLoading={isLoading} />
            <TeamHeaderTitle
                title={"Editando time " + team?.name}
                description="Altere as informações do time abaixo" />
            <BackButton onClick={() => navigate(-1)} />
            <HandleTeamForm
                onChangeField={handleChange}
                onSubmitForm={onSubmitForm}
                onlyReadName={true}
                onSelectImage={handleImageChanged}
                formValid={isFormValid}
                teamParams={formParams}>
                <EditionFormButtons
                    onClickToDelete={onClickToDeleteTeam}
                    onClickToEditEscalation={() => navigate(URL_PAGES.ESCALATION_LIST, { state: { team: team } })} />
            </HandleTeamForm>
            <EasyFeedbackModal
                show={feedback?.show ?? false}
                title={feedback?.title}
                description={feedback?.message}
                listener={() => {
                    feedback?.clickToConfirm()
                    setFeedback(undefined)
                }}
            />
            <SeparatorLine className="my-5" />
            <CollaboratorList
                onClickToRemoveCollaborator={(collaborator: Collaborator) => setDeleteCollaboratorModal({ show: true, collaboratorSelected: collaborator })}
                onClickToAddCollaborator={() => setShowCollaboratorModal(true)}
                collaborators={collaborators} />
            <SelectCollaboratorModal
                collaboratorSelected={(collaborator, date) => {
                    setShowCollaboratorModal(false);
                    addCollaboratorToTeam(collaborator, date);
                }}
                show={showCollaboratorModal}
                onCloseModal={() => setShowCollaboratorModal(false)}
            />
            <DeleteCollaboratorModal
                onCloseModal={() => setDeleteCollaboratorModal(undefined)}
                collaborator={deleteCollaboratorModal?.collaboratorSelected}
                show={deleteCollaboratorModal?.show ?? false}
                onSubmitValidForm={removeCollaboratorToTeam}
            />
        </TeamContainer >
    );
}