import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { InvoiceScreenHeader } from "./components/InvoiceHeaderTitle";
import { InvoiceUploadList } from "./components/InvoiceUploadList";
import { Invoice, useInvoiceService } from "./hooks/useInvoiceService";
import EasyFeedbackModal from "components/EasyFeedbackModal";
import Loading from "components/Loading";
import useInvoiceUpload from "./hooks/useInvoiceUpload";

interface Feedback {
    title: string,
    message: string,
    listener: () => void
}

export default function CollaboratorInvoice() {
    const [serviceState, reloadInvoices] = useInvoiceService();
    const { uploadState, handleFileSelected } = useInvoiceUpload();
    const [feedback, setFeedback] = useState<Feedback>();
    const [loading, setLoading] = useState<boolean>(false);
    const [invoices, setInvoices] = useState<Invoice[]>([]);

    const onInvoiceServiceStateChanged = () => {
        switch (uploadState?.type) {
            case 'success':
                setLoading(false);
                setFeedback(
                    {
                        title: "Enviado com sucesso!",
                        message: "Sua nota fiscal foi enviada com sucesso.",
                        listener: () => {
                            setFeedback(undefined)
                            reloadInvoices()
                        }
                    }
                )
                break;
            case 'error':
                setLoading(false);
                setFeedback(
                    {
                        title: "Houve um erro",
                        message: uploadState?.message,
                        listener: () => setFeedback(undefined)
                    }
                )
                break;
            case 'loading':
                setLoading(true);
                break;
            default:
                console.log("Estado não mapeado...")
                break;
        }
    }

    const onUploadStateChanged = () => {
        switch (serviceState?.type) {
            case 'success':
                setLoading(false);
                setInvoices(serviceState.invoices)
                break;
            case 'error':
                setLoading(false);
                setFeedback(
                    {
                        title: "Houve um erro",
                        message: serviceState.message,
                        listener: () => setFeedback(undefined)
                    }
                )
                break;
            case 'loading':
                setLoading(true);
                break;
            default:
                console.log("Estado não mapeado...")
                break;
        }
    };

    useEffect(onUploadStateChanged, [serviceState])
    useEffect(onInvoiceServiceStateChanged, [uploadState])

    return (
        <Container>
            <Loading isLoading={loading} />
            <InvoiceScreenHeader
                title="Lançamento de notas fiscais"
                description="Faça o upload das notas fiscais na opções abaixo." />
            <InvoiceUploadList
                onFileSelected={handleFileSelected}
                invoices={invoices} />
            <EasyFeedbackModal
                show={feedback}
                title={feedback?.title}
                description={feedback?.message}
                listener={feedback?.listener}
            />
        </Container>
    );
}