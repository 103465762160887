import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container, Form, Col, Row, Alert } from "react-bootstrap";
import styled from "styled-components";
import { useState } from "react";
import { SERVICES } from "utils/constants";
import Loading from "components/Loading";
import api from "api";

const FormContainer = styled.div`
    width: fit-content;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 1px 1px 3px #777777;
    border: 1px solid lightgrey;
`;

const CreateEscalation = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { team } = location.state

    const screenFormInitialState = {
        approver: false,
        idTeam: team.id,
        loginCoord1: "",
        loginCoord2: "",
        ordination: ""
    }
    const feedbackInitialState = {
        show: false,
        message: "",
        type: "",
        title: ""
    }

    const [isLoading, setLoading] = useState(false);
    const [feedbackState, setFeedbackState] = useState(feedbackInitialState);
    const [formValid, setFormValid] = useState(false);
    const [formState, setFormState] = useState(screenFormInitialState);

    const handleChange = (event) => {
        let { name, value } = event.target
        console.log(formState)

        if (name === "approver") {
            value = event.target.checked
        }

        setFormState({ ...formState, [name]: value })
    }

    const onSubmitForm = (event) => {
        setFeedbackState(feedbackInitialState)
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        const isFormValid = form.checkValidity() === true
        if (isFormValid) {
            saveEscalation()
        }
        
        setFormValid(true);
    }

    const showFeedback = (newTitle, newMessage, newType) => {
        setFeedbackState({
            show: true,
            message: newMessage,
            type: newType,
            title: newTitle
        })
    }

    const saveEscalation = async () => {
        try {
            setLoading(true)
            await api.post(SERVICES.ESCALATION, formState)
            showFeedback(
                "Cadastrado com sucesso!",
                "A escalation foi registrada com sucesso...",
                "success"
            )
            setFormState(screenFormInitialState)
            setFormValid(false);
        } catch (error) {
            const errorMessage = error.response.data?.message
            showFeedback("Houve um erro!", errorMessage ?? "Ops, houve um erro...", "warning")
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <Row className="d-flex justify-content-center m-1">
                <Alert variant="info" className="col-12">
                    <Alert.Heading>Criando escalation do time: {team.name}</Alert.Heading>
                </Alert>
                <Alert variant={feedbackState.type} show={feedbackState.show} dismissible onClose={() => setFeedbackState(feedbackInitialState)}>
                    <Alert.Heading>{feedbackState.title}</Alert.Heading>
                    <p>{feedbackState.message}</p>
                </Alert>
            </Row>
            <Form
                id="escalation-form"
                className="row d-flex justify-content-center m-1"
                noValidate
                validated={formValid}
                onSubmit={onSubmitForm}>
                <Col lg={8} xxl={5}>
                    <FormContainer className="row d-flex justify-content-center g-2" >
                        <Form.Group className="col-12" controlId="formBasicEmail">
                            <Form.Label>Time selecionado</Form.Label>
                            <Form.Control type="text" placeholder={team.name} disabled />
                        </Form.Group>
                        <Form.Group className="col-12" >
                            <Form.Label>Usuário do coordenador 1:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="fulano.sobrenome"
                                onChange={handleChange}
                                value={formState.loginCoord1}
                                name="loginCoord1"
                                required />
                            <Form.Control.Feedback type="invalid">
                                Usuário do coordenador 1 obrigatório.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="col-12" >
                            <Form.Label>Usuário do coordenador 2:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="beltrano.sobrenome (Opcional)"
                                name="loginCoord2"
                                value={formState.loginCoord2}
                                onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="col-12" >
                            <Form.Label>Ordem da escalation:</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="0"
                                value={formState.ordination}
                                onChange={handleChange}
                                name="ordination"
                                required />
                            <Form.Control.Feedback type="invalid">
                                É necessário informar a ordem da escalation.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="col-12 mt-3" >
                            <Form.Check
                                type="checkbox"
                                label="Coordenadores serão aprovadores?"
                                name="approver"
                                checked={formState.approver}
                                onChange={handleChange} />
                        </Form.Group>
                        <Button
                            variant="outline-primary"
                            className="col-lg-12 py-2 mt-4"
                            onClick={() => navigate(-1)}>Voltar</Button>
                        <Button
                            className="col-lg-12 py-2"
                            variant="primary"
                            type="submit">
                            Cadastrar
                        </Button>
                    </FormContainer>
                </Col>
            </Form>
            <Loading isLoading={isLoading} />
        </Container>
    );
}

export default CreateEscalation;