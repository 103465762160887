import React from "react";
import { Button, Col, Row, } from "react-bootstrap";

export default function BackButton(props: {onClick: ()=> void}) {
    return <Row className="g-4">
        <Col sm={5} lg={6}>
            <Button variant="outline-primary" onClick={props.onClick}>
                Voltar
            </Button>
        </Col>
    </Row>;
}